$(document).ready(function() {
  $('.tool-tip-info').tooltip()
  ClearBoxInit();
  cargarSeccionSegmentacion();
});
var typeDoc = "01";
function cargarSeccionSegmentacion() {
  let pathname = window.location.pathname;
  if (pathname.includes("segmentacion.html")) {
    var objData = localStorage.getItem("objData");
    if (objData != null && objData != "") {
      let listData = JSON.parse(objData);
      if (listData.length > 0) {
        let clasePuntos = "";
        let clientePuntos = "";
        let nombres = "";
        let clientePuntosObjetivo = "";
        let fechaRipleyPuntos = "";
        let puntaje = "";
        let puntajeFaltante = "";
        let puntajePorcentaje = "";
        let totalRipleyPuntos = "";
        for (let y = 0; y < listData.length; y++) {
          if (listData[y].name == "CLASE_PUNTOS") {
            clasePuntos = listData[y].value;
          }
          if (listData[y].name == "CLASE_PUNTOS_CLIENTES") {
            clientePuntos = listData[y].value;
          }
          if (listData[y].name == "CLASE_PUNTOS_CLIENTES_OBJETIVO") {
            clientePuntosObjetivo = listData[y].value;
          }
          if (listData[y].name == "FECHA_RIPLEYPUNTOS") {
            fechaRipleyPuntos = listData[y].value;
          }
          if (listData[y].name == "NOMBRE") {
            nombres = listData[y].value;
          }
          if (listData[y].name == "PUNTAJE") {
            puntaje = listData[y].value;
          }
          if (listData[y].name == "PUNTAJE_FALTANTE") {
            puntajeFaltante = listData[y].value =="" ? "0" : listData[y].value;
          }
          if (listData[y].name == "PUNTAJE_PORCENTAJE") {
            puntajePorcentaje = listData[y].value =="" ? "0" : listData[y].value ;
          }
          if (listData[y].name == "RIPLEYPUNTOS") {
            totalRipleyPuntos_ = listData[y].value;
            totalRipleyPuntos=formatNumber.new(totalRipleyPuntos_);
            
          }
        }
        nombres = nombres + "!";
        var windowsize = $(window).width();
        if (clientePuntos.toUpperCase() == "SILVER PRIME") {
          localStorage.setItem("idSeccionSegmentador", "divSegmentadorSiver");
          $("#divSegmentadorRipley").hide();
          $("#divSegmentadorGold").hide();
          $("#divSegmentadorNoClientes").hide();
          $("#divSegmentadorSiver").show();

          $("#divSegmentadorSiver #nombre").text(nombres);
          $("#divSegmentadorSiver #puntaje").text(puntaje);
          $("#divSegmentadorSiver #puntajeTotal").text(totalRipleyPuntos);
          $("#divSegmentadorSiver #puntajeFaltante").text(puntajeFaltante);
          $("#divSegmentadorSiver #porcentajeBarra").width(
            puntajePorcentaje + "%"
          );
          $("#divSegmentadorSiver #porcentaje").width(puntajePorcentaje + "%");
          $("#divSegmentadorSiver #porcentaje").text(puntajePorcentaje + "%");

          if (windowsize <= 767.98) {
            mostrarSilver();
            // $("#divSegmentadorSiver #seccionSilver").show();
            // $("#divSegmentadorSiver #seccionGold").hide();
            // $("#divSegmentadorSiver #markerGrey").addClass("hr-rpg-grey");
            // $("#divSegmentadorSiver #markerGold").removeClass("hr-rpg-gold");
          }
        } else if (clientePuntos.toUpperCase() == "GOLD PRIME") {
          localStorage.setItem("idSeccionSegmentador", "divSegmentadorGold");
          $("#divSegmentadorRipley").hide();
          $("#divSegmentadorSiver").hide();
          $("#divSegmentadorNoClientes").hide();
          $("#divSegmentadorGold").show();

          $("#divSegmentadorGold #nombre").text(nombres);
          $("#divSegmentadorGold #puntaje").text(puntaje);
          $("#divSegmentadorGold #puntajeTotal").text(totalRipleyPuntos);
          $("#divSegmentadorGold #puntajeFaltante").text(puntajeFaltante);
          $("#divSegmentadorGold #porcentajeBarra").width(
            puntajePorcentaje + "%"
          );
          $("#divSegmentadorGold #porcentaje").width(puntajePorcentaje + "%");
          $("#divSegmentadorGold #porcentaje").text(puntajePorcentaje + "%");

          if (windowsize <= 767.98) {
            mostrarGold();
            // $("#divSegmentadorGold #seccionGold").show();
            // $("#divSegmentadorGold #seccionSilver").hide();
            // $("#divSegmentadorGold #markerGrey").removeClass("hr-rpg-grey");
            // $("#divSegmentadorGold #markerGold").addClass("hr-rpg-gold");
          }

          if (totalRipleyPuntos=="" || totalRipleyPuntos=="0" || totalRipleyPuntos==null || totalRipleyPuntos==undefined ) {
            $(".divNoNormalInfoGold").attr("style", "display:block");
            $(".divNormalInfoGold").attr("style", "display:none");
          }


        } else if (clientePuntos.toUpperCase() == "RIPLEY") {
          localStorage.setItem("idSeccionSegmentador", "divSegmentadorSiver");
          $("#divSegmentadorSiver").hide();
          $("#divSegmentadorGold").hide();
          $("#divSegmentadorNoClientes").hide();
          $("#divSegmentadorRipley").show();

          $("#divSegmentadorRipley #nombre").text(nombres);
          $("#divSegmentadorRipley #puntaje").text(puntaje);
          $("#divSegmentadorRipley #puntajeTotal").text(totalRipleyPuntos);
          $("#divSegmentadorRipley #puntajeFaltante").text(puntajeFaltante);
          $("#divSegmentadorRipley #porcentajeBarra").width(
            puntajePorcentaje + "%"
          );
          $("#divSegmentadorRipley #porcentaje").width(puntajePorcentaje + "%");
          $("#divSegmentadorRipley #porcentaje").text(puntajePorcentaje + "%");

          if (windowsize <= 767.98) {
            mostrarSilver();
            // $("#divSegmentadorRipley #seccionSilver").show();
            // $("#divSegmentadorRipley #seccionGold").hide();
            // $("#divSegmentadorRipley #markerGrey").addClass("hr-rpg-grey");
            // $("#divSegmentadorRipley #markerGold").removeClass("hr-rpg-gold");
          }
        }
      } else {
        $("#divSegmentadorSiver").hide();
        $("#divSegmentadorGold").hide();
        $("#divSegmentadorRipley").hide();
        $("#divSegmentadorNoClientes").show();
      }
    } else {
      localStorage.setItem("idSeccionSegmentador", "divSegmentadorNoClientes");
      $("#divSegmentadorSiver").hide();
      $("#divSegmentadorGold").hide();
      $("#divSegmentadorRipley").hide();
      $("#divSegmentadorNoClientes").show();
      mostrarSilver();
    }
  }
}

var formatNumber = {
  separador: ",", // separador para los miles
  sepDecimal: '.', // separador para los decimales
  formatear:function (num){
  num +='';
  var splitStr = num.split('.');
  var splitLeft = splitStr[0];
  var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
  var regx = /(\d+)(\d{3})/;
  while (regx.test(splitLeft)) {
  splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
  }
  return this.simbol + splitLeft +splitRight;
  },
  new:function(num, simbol){
  this.simbol = simbol ||'';
  return this.formatear(num);
  }
 }

function RegistroOMPAfiliate() {
  window.location.href = "registro-omp.html";
}

function SolictarTarjeta() {
  window.open(
    "https://www.bancoripley.com.pe/solicita-tarjeta-ripley-mastercard/index.html",
    "_blank"
  );
}

function consultarPointsClient(token) {
  let document = $("#secctionContenedorClientPrime #nroDocumento").val();
  let client = {
    typeDocument: typeDoc,
    numberDocument: document,
    flagClient: "1",
    captcha: token
  };
  
  localStorage.setItem("objData", "");
  pantallaDeCarga(true);
  getPointsClient(client)
    .done(function(data) {
      if (data.result != "") {
        if (data.result[0] != null) {
          if (data.result.length > 0) {
            let list = data.result[0].data;
            for (let i = 0; i < list.length; i++) {
              if (list[i].customFields.length > 0) {
                let listData = list[i].customFields;
                localStorage.setItem("objData", JSON.stringify(listData));
                ripleyPuntosAnalytics.addEvent(
                  "Segmentacion_Ripleypuntos",
                  "Consulta",
                  "Consulta Exitosa",
                  0
                );
                RegistrarNumeroDocumentoConsultado(typeDoc,document,'01');
                window.location.href = "./segmentacion.html";
              }
            }
          } else {
            ripleyPuntosAnalytics.addEvent(
              "Segmentacion_Ripleypuntos",
              "Consulta",
              "Consulta No Exitosa",
              0
            );
            RegistrarNumeroDocumentoConsultado(typeDoc,document,'01');
            window.location.href = "./segmentacion.html";
          }
        } else {
          ripleyPuntosAnalytics.addEvent(
            "Segmentacion_Ripleypuntos",
            "Consulta",
            "Consulta No Exitosa",
            0
          );
          RegistrarNumeroDocumentoConsultado(typeDoc,document,'01');
          window.location.href = "./segmentacion.html";
        }
      } else {
        ripleyPuntosAnalytics.addEvent(
          "Segmentacion_Ripleypuntos",
          "Consulta",
          "Consulta No Exitosa",
          0
        );
        RegistrarNumeroDocumentoConsultado(typeDoc,document,'01');
        window.location.href = "./segmentacion.html";
      }
      pantallaDeCarga(false);
    })
    .fail(function(jqXHR, textStatus, errorThrown) {
      //error
      pantallaDeCarga(false);
    });
}

function consultarPointsClientCP(token) {
  let pathname = window.location.pathname;
  let document = $("#secctionContenedorClientPrimeCP #nroDocumentoCP").val();
  let document2 = $("#secctionContenedorClientPrimeCP #nroDocumentoCP2").val();
  if (document == "") {
    document = document2;
  }
  let client = {
    typeDocument: "01",
    numberDocument: document,
    flagClient: "1",
    captcha: token
  };
  localStorage.setItem("objDataCP", "");
  localStorage.setItem("flg_dniconsulted", "");
  pantallaDeCarga(true);
  getPointsClient(client)
    .done(function(data) {
      if (data.result != "") {
        if (data.result[0] != null) {
          if (data.result.length > 0) {
            let list = data.result[0].data;
            for (let i = 0; i < list.length; i++) {
              if (list[i].customFields.length > 0) {
                let listData = list[i].customFields;
                localStorage.setItem("objDataCP", JSON.stringify(listData));
                ripleyPuntosAnalytics.addEvent(
                  "Ripleypuntos",
                  "Consulta",
                  "Consulta Exitosa",
                  0
                );
                RegistrarNumeroDocumentoConsultado(typeDoc,document,'02');
                window.location.href = pathname;
              }
            }
          } else {
            ripleyPuntosAnalytics.addEvent(
              "Ripleypuntos",
              "Consulta",
              "Consulta No Exitosa",
              0
            );
            RegistrarNumeroDocumentoConsultado(typeDoc,document,'02');
            window.location.href = pathname;
          }
        } else {
          ripleyPuntosAnalytics.addEvent(
            "Ripleypuntos",
            "Consulta",
            "Consulta No Exitosa",
            0
          );
          RegistrarNumeroDocumentoConsultado(typeDoc,document,'02');
          window.location.href = pathname;
        }
      } else {
        ripleyPuntosAnalytics.addEvent(
          "Ripleypuntos",
          "Consulta",
          "Consulta No Exitosa",
          0
        );
        RegistrarNumeroDocumentoConsultado(typeDoc,document,'02');
        window.location.href = pathname;
      }
      localStorage.setItem("flg_dniconsulted", "1");
      pantallaDeCarga(false);
    })
    .fail(function(jqXHR, textStatus, errorThrown) {
      pantallaDeCarga(false);
    });
}

function GetReCaptchaID(containerID) {
  var retval = -1;
  $(".g-recaptcha").each(function(index) {
    if (this.id == containerID) {
      retval = index;
      return;
    }
  });

  return retval;
}

$("#secctionContenedorClientPrime #btnEnviar").on("click", function() {
  // let nroDocumentoValidate = divConsultarPrestamo();
  let nroDocumentoValidate = divNroDocumentoValidate();

  if (!nroDocumentoValidate) {
    //marcacionErrorNroDocumento();
  }
  if (nroDocumentoValidate) {
    // event.preventDefault();
    // var reCaptchaID = GetReCaptchaID("recaptcha");
    // grecaptcha.reset(reCaptchaID);
    // grecaptcha.execute(reCaptchaID);
    grecaptcha.execute();
  }
});

$("#secctionContenedorClientPrimeCP #btnEnviarCP").on("click", function() {
  let nroDocumentoValidate = divConsultarPrestamoCP();

  if (!nroDocumentoValidate) {
    //marcacionErrorNroDocumento();
  }
  if (nroDocumentoValidate) {
    grecaptcha.execute();
  }
});


$("#secctionContenedorClientPrimeCP #btnEnviarCP2").on("click", function() {
  let nroDocumentoValidate = divConsultarPrestamoCP2();

  if (!nroDocumentoValidate) {
    //marcacionErrorNroDocumento();
  }
  if (nroDocumentoValidate) {
    grecaptcha.execute();
  }
});


$("#sectionBeneCategorias #btnEnviar").on("click", function() {
  window.location.href = "https://www.bancoripley.com.pe/promociones/index.html";
});







// $("#secctionContenedorClientPrime #nroDocumento").blur(function() {
//   divConsultarPrestamo();
// });
$("#secctionContenedorClientPrime #nroDocumento").keyup(function () {
  let documentoMessage = $("#secctionContenedorClientPrime #documento-message");
  if (documentoMessage[0].innerText != "")
      divNroDocumentoValidate();
});

function divNroDocumentoValidate() {
  ultimoValorNumeros = "";
  let isValid = true;
  let msgDocumento = "";
  let txtNroDocumento = $("#secctionContenedorClientPrime #nroDocumento");
  let documentoMessage = $("#secctionContenedorClientPrime #documento-message");

  if (!(txtNroDocumento && txtNroDocumento.val() != "")) {
      txtNroDocumento.addClass("is-invalid");
      msgDocumento = "Ingresa tu número de documento.";
      isValid = false;
  }
  else {
      if ($(".boxDni").hasClass("boxSelected")) {
          if (txtNroDocumento.val().length != 8) {
              txtNroDocumento.addClass("is-invalid");
              msgDocumento = "Ingresa tu número de documento.";
              isValid = false;
          }
          else
              txtNroDocumento.removeClass("is-invalid");
      }
      else {
          if (txtNroDocumento.val().length < 9 || txtNroDocumento.val().length > 11) {
              txtNroDocumento.addClass("is-invalid");
              msgDocumento = "Ingresa tu número de documento.";
              isValid = false;
          }
          else
              txtNroDocumento.removeClass("is-invalid");
      }

  }
  if (msgDocumento) {
      documentoMessage.empty();
      documentoMessage.html(msgDocumento);
  }
  return isValid;
}
function divConsultarPrestamo() {
  let isValid = true;
  let msgDocumento = "";
  let txtNroDocumento = $("#secctionContenedorClientPrime #nroDocumento");
  let documentoMessage = $("#secctionContenedorClientPrime #documento-message");

  if (!(txtNroDocumento && txtNroDocumento.val() != "")) {
    txtNroDocumento.addClass("is-invalid");
    msgDocumento = "Ingresa un DNI válido.";
    isValid = false;
  } else {
    if (txtNroDocumento.val().length != 8) {
      txtNroDocumento.addClass("is-invalid");
      msgDocumento = "Ingresa un DNI válido.";
      isValid = false;
    } else txtNroDocumento.removeClass("is-invalid");
  }

  if (msgDocumento) {
    documentoMessage.empty();
    documentoMessage.html(msgDocumento);
  }

  return isValid;
}

$("#secctionContenedorClientPrimeCP #nroDocumentoCP").blur(function() {
  divConsultarPrestamoCP();
});
function divConsultarPrestamoCP() {
  let isValid = true;
  let msgDocumento = "";
  let txtNroDocumento = $("#secctionContenedorClientPrimeCP #nroDocumentoCP");
  let documentoMessage = $(
    "#secctionContenedorClientPrimeCP #documento-messageCP"
  );

  if (!(txtNroDocumento && txtNroDocumento.val() != "")) {
    txtNroDocumento.addClass("is-invalid");
    msgDocumento = typeDoc=="01" ? "Ingresa un DNI válido." : "Ingresa un Carné de Extranjería válido.";
    isValid = false;
  } else {
    if (txtNroDocumento.val().length != (typeDoc=="01" ? 8 : 11)) {
      txtNroDocumento.addClass("is-invalid");
      msgDocumento = typeDoc=="01" ? "Ingresa un DNI válido." : "Ingresa un Carné de Extranjería válido.";
      isValid = false;
    } else txtNroDocumento.removeClass("is-invalid");
  }

  if (msgDocumento) {
    documentoMessage.empty();
    documentoMessage.html(msgDocumento);
  }

  return isValid;
}
$("#secctionContenedorClientPrimeCP #nroDocumentoCP2").blur(function() {
  divConsultarPrestamoCP2();
});
function divConsultarPrestamoCP2() {
  let isValid = true;
  let msgDocumento = "";
  let txtNroDocumento = $("#secctionContenedorClientPrimeCP #nroDocumentoCP2");
  let documentoMessage = $(
    "#secctionContenedorClientPrimeCP #documento-messageCP2"
  );

  if (!(txtNroDocumento && txtNroDocumento.val() != "")) {
    txtNroDocumento.addClass("is-invalid");
    msgDocumento = typeDoc=="01" ? "Ingresa un DNI válido." : "Ingresa un Carné de Extranjería válido.";
    isValid = false;
  } else {
    if (txtNroDocumento.val().length != (typeDoc=="01" ? 8 : 11)) {
      txtNroDocumento.addClass("is-invalid");
      msgDocumento = typeDoc=="01" ? "Ingresa un DNI válido." : "Ingresa un Carné de Extranjería válido.";
      isValid = false;
    } else txtNroDocumento.removeClass("is-invalid");
  }

  if (msgDocumento) {
    documentoMessage.empty();
    documentoMessage.html(msgDocumento);
  }

  return isValid;
}

function mostrarSilver() {
  let idSecSegmentador = localStorage.getItem("idSeccionSegmentador");
  $("#" + idSecSegmentador + " #markerGold").hide();
  $("#" + idSecSegmentador + " #markerGrey").show();

  $("#" + idSecSegmentador + " #seccionGold").animate(
    {
      "margin-left": "100%"
    },
    500
  );

  $("#" + idSecSegmentador + " #seccionSilver").animate(
    {
      "margin-left": "0%"
    },
    500
  );
  setTimeout(function(){  $("#" + idSecSegmentador + " #seccionGold").css("display", "none"); }, 400);
 
}
function mostrarGold() {
  let idSecSegmentador = localStorage.getItem("idSeccionSegmentador");
  $("#" + idSecSegmentador + " #markerGrey").hide();
  $("#" + idSecSegmentador + " #markerGold").show();
  $("#" + idSecSegmentador + " #seccionGold").css("display", "block");
  $("#" + idSecSegmentador + " #seccionSilver").animate(
    {
      "margin-left": "-100%"
    },
    500
  );
  $("#" + idSecSegmentador + " #seccionGold").animate(
    {
      "margin-left": "0%"
    },
    500
  );
}
function pantallaDeCarga(booleano) {
  if (booleano) {
    $(".app-opacity").show();
  } else {
    $(".app-opacity").hide();
  }
}
$("#appModalPuntosCalificables").on("show.bs.modal", function(e) {
  $("#header").addClass("opacity_ModalPC");
});
$("#appModalPuntosCalificables").on("hide.bs.modal", function(e) {
  $("#header").removeClass("opacity_ModalPC");
});
function selectedTypeDoc(type) {
    typeDoc = type;
}
$(".boxDni").on('click', function () {
  ClearBoxInit();
  $(".boxDni").addClass("boxSelected");
  $(".boxCe").removeClass("boxSelected");
  $(".textDni").addClass("textSelected");
  $(".textDni").removeClass("textUnselected");
  $(".textCe").removeClass("textSelected");
  $(".textCe").addClass("textUnselected");
  $("#nroDocumento").attr('maxlength', '8');
});

$(".boxCe").on('click', function () {
  ClearBoxInit();
  $(".boxCe").addClass("boxSelected");
  $(".boxDni").removeClass("boxSelected");
  $(".textCe").addClass("textSelected");
  $(".textCe").removeClass("textUnselected");
  $(".textDni").addClass("textUnselected");
  $(".textDni").removeClass("textSelected");
  $("#nroDocumento").attr('maxlength', '11');
});
function ClearBoxInit() {
  $("#secctionContenedorClientPrime #nroDocumento").val("");
  $("#secctionContenedorClientPrime #nroDocumento").removeClass("is-invalid");
  $("#secctionContenedorClientPrime #documento-message").empty();
}

$(".boxDniCP").on('click', function () {
  ClearBoxInitCP();
  $(".boxDniCP").addClass("boxSelected");
  $(".boxCeCP").removeClass("boxSelected");
  $(".textDni").addClass("textSelected");
  $(".textDni").removeClass("textUnselected");
  $(".textCe").removeClass("textSelected");
  $(".textCe").addClass("textUnselected");
  
  $("#nroDocumentoCP").attr('placeholder', 'Número de DNI');
  $("#nroDocumentoCP").attr('maxlength', '8');
});

$(".boxCeCP").on('click', function () {
  ClearBoxInitCP();
  $(".boxCeCP").addClass("boxSelected");
  $(".boxDniCP").removeClass("boxSelected");
  $(".textCe").addClass("textSelected");
  $(".textCe").removeClass("textUnselected");
  $(".textDni").addClass("textUnselected");
  $(".textDni").removeClass("textSelected");
  $("#nroDocumentoCP").attr('placeholder', 'Número de Carné de Extranjería');
  $("#nroDocumentoCP").attr('maxlength', '11');
});
function ClearBoxInitCP() {
  $("#secctionContenedorClientPrimeCP #nroDocumentoCP").val("");
  $("#secctionContenedorClientPrimeCP #nroDocumentoCP").removeClass("is-invalid");
  $("#secctionContenedorClientPrimeCP #documento-messageCP").empty();
}

$(".boxDniCP2").on('click', function () {
  ClearBoxInitCP2();
  $(".boxDniCP2").addClass("boxSelected");
  $(".boxCeCP2").removeClass("boxSelected");
  $(".textDni").addClass("textSelected");
  $(".textDni").removeClass("textUnselected");
  $(".textCe").removeClass("textSelected");
  $(".textCe").addClass("textUnselected");
  
  $("#nroDocumentoCP2").attr('placeholder', 'Número de DNI');
  $("#nroDocumentoCP2").attr('maxlength', '8');
});

$(".boxCeCP2").on('click', function () {
  ClearBoxInitCP2();
  $(".boxCeCP2").addClass("boxSelected");
  $(".boxDniCP2").removeClass("boxSelected");
  $(".textCe").addClass("textSelected");
  $(".textCe").removeClass("textUnselected");
  $(".textDni").addClass("textUnselected");
  $(".textDni").removeClass("textSelected");
  $("#nroDocumentoCP2").attr('placeholder', 'Número de Carné de Extranjería');
  $("#nroDocumentoCP2").attr('maxlength', '11');
});
function ClearBoxInitCP2() {
  $("#secctionContenedorClientPrimeCP #nroDocumentoCP2").val("");
  $("#secctionContenedorClientPrimeCP #nroDocumentoCP2").removeClass("is-invalid");
  $("#secctionContenedorClientPrimeCP #documento-messageCP2").empty();
}

//registro de nro de documento
function RegisterInscripcionNroDocumentoFormCMS(InscriptionFormCms){
  var result = getAuthentication();
  var token = result.responseJSON.access_token;
  var autorizacion = "Bearer " + token;
  return $.ajax({
      type: "POST",
      url: apiRegisterFormCMS,
      data: JSON.stringify(InscriptionFormCms), 
      async: false,
      headers: {
          "Content-Type" : 'application/json',
          "Authorization" : autorizacion,
      },
  });
}

function RegistrarNumeroDocumentoConsultado(TypeDocument,document,TipoIdForm){
  let _tipodocumento = (TypeDocument == '01' ? 'DNI' : 'CE');
  let _formid = (TipoIdForm == '01' ? 'ID_FORM_SEGMENTACION' : 'ID_FORM_RIPLEYPUNTOS');
  let trama='formId►' + _formid + '▲names►▲firstName►▲lastName►▲documentType►' + _tipodocumento + '▲documentNumber►' + document + '▲email►▲mobilePhone►▲promotionCode►▲purchaseNumber►▲turnBuy►▲businessName►▲dataTreatment►';
  
  let InscriptionFormCms = {
      trama: trama,
      captcha : "",
      sourcecaptcha: "RP"
  }
  RegisterInscripcionNroDocumentoFormCMS(InscriptionFormCms)
  .done(function(data){
    if (data.status==200) {
         //registro correctamente
      } 
      
  })
  .fail(function( jqXHR, textStatus, errorThrown ){
    console.log('error al registrar documento')
  })

};