document.addEventListener('DOMContentLoaded', function () {
    var simple = document.querySelector('.js_simple');
    var barra = document.getElementById('navSlide1') && document.getElementById('navSlide2') && document.getElementById('navSlide3') && document.getElementById('navSlide4');

    if (simple) {
        lory(simple, {
            //infinite: 1,
            slideSpeed: 1100,
            enableMouseEvents: true
        });
        var lorySlider = lory(simple, {
            infinite: 1,
            slideSpeed: 1100,
            enableMouseEvents: true
        });

        if (barra) {
            document.getElementById('navSlide1').addEventListener('click', function () {
                document.getElementById('navSlide2').classList.remove('active');
                document.getElementById('navSlide3').classList.remove('active');
                document.getElementById('navSlide4').classList.remove('active');
                this.classList.add('active');
                lorySlider.slideTo(0);
                resetTimer();
            });
            document.getElementById('navSlide2').addEventListener('click', function () {
                document.getElementById('navSlide1').classList.remove('active');
                document.getElementById('navSlide3').classList.remove('active');
                document.getElementById('navSlide4').classList.remove('active');
                this.classList.add('active');
                lorySlider.slideTo(1);
                resetTimer();
            });
            document.getElementById('navSlide3').addEventListener('click', function () {
                document.getElementById('navSlide1').classList.remove('active');
                document.getElementById('navSlide2').classList.remove('active');
                document.getElementById('navSlide4').classList.remove('active');
                this.classList.add('active');
                lorySlider.slideTo(2);
                resetTimer();
            });
            document.getElementById('navSlide4').addEventListener('click', function () {
                document.getElementById('navSlide1').classList.remove('active');
                document.getElementById('navSlide2').classList.remove('active');
                document.getElementById('navSlide3').classList.remove('active');
                this.classList.add('active');
                lorySlider.slideTo(3);
                resetTimer();
            });
        }

        document.querySelector('a.js_prev').addEventListener('click', function () {
            CambiarSlider();
            resetTimer();
        });
        document.querySelector('a.js_next').addEventListener('click', function () {
            CambiarSlider();
            resetTimer();
        });

        function CambiarSlider() {
            if (barra) {
                if (document.querySelector('#slide1').classList.contains('active')) {
                    document.getElementById('navSlide4').classList.remove('active');
                    document.getElementById('navSlide2').classList.remove('active');
                    document.getElementById('navSlide3').classList.remove('active');
                    document.getElementById('navSlide1').classList.add('active');
                }
                else if (document.querySelector('#slide2').classList.contains('active')) {
                    document.getElementById('navSlide1').classList.remove('active');
                    document.getElementById('navSlide3').classList.remove('active');
                    document.getElementById('navSlide4').classList.remove('active');
                    document.getElementById('navSlide2').classList.add('active');
                }
                else if (document.querySelector('#slide3').classList.contains('active')) {
                    document.getElementById('navSlide2').classList.remove('active');
                    document.getElementById('navSlide4').classList.remove('active');
                    document.getElementById('navSlide1').classList.remove('active');
                    document.getElementById('navSlide3').classList.add('active');
                }
                else {
                    document.getElementById('navSlide3').classList.remove('active');
                    document.getElementById('navSlide2').classList.remove('active');
                    document.getElementById('navSlide1').classList.remove('active');
                    document.getElementById('navSlide4').classList.add('active');
                }
            }
        }

        var delay = 5000; //5 seconds
        var intervaloSlider;

        resetTimer();

        function resetTimer() {
            clearTimeout(intervaloSlider);
            intervaloSlider = setInterval(function () {
                lorySlider.next();
                CambiarSlider();
            }, delay);
        }


        //prueba de commit
    }
});

var appModal = document.getElementById('appModal');
$('.close').on('click', function () {
    appModal.style.display = 'none';
    restartScrolling();
});

function stopScrolling() {
    var a = document.querySelector(".mobile-modal-layout");
    if (a) {
        "flex" != window.getComputedStyle(a).display ? restartScrolling() : (body = document.getElementsByTagName("body")[0],
            body.setAttribute("style", "overflow:hidden"),
            body.setAttribute("style", "position:fixed"))
    }
}

function restartScrolling() {
    body = document.getElementsByTagName("BODY")[0];
    body.removeAttribute("style")
}

$(window).scroll(function (event) {
    var scroll_top = $(window).scrollTop();
    var screensize = $(window).width();
    if (scroll_top > 150 && screensize >= 1024) {
        $("#header").removeClass("ripley-header-flotante");
        $("#header").removeClass("pt-2");
        $("#header").addClass("ripley-header");
        $("#menu").removeClass("menu-br-flotante");
        $("#menu").addClass("menu-br");

        $("#menuNavBar2").removeClass("flexfillheader");
        $("#menuNavBar2").addClass("flexfillheader-br");


    }
    if (scroll_top < 150 && screensize >= 1024) {
        $("#header").addClass("ripley-header-flotante");
        $("#header").addClass("pt-2");
        $("#header").removeClass("ripley-header");
        $("#menu").addClass("menu-br-flotante");
        $("#menu").removeClass("menu-br");
        $("#menuNavBar2").addClass("flexfillheader");
        $("#menuNavBar2").removeClass("flexfillheader-br");
    }
    if (screensize < 1024) {
        $("#header").removeClass("ripley-header-flotante");
        $("#header").removeClass("pt-2");
        $("#header").addClass("ripley-header");
        $("#menu").removeClass("menu-br-flotante");
        $("#menu").addClass("menu-br");
        $("#menuNavBar2").removeClass("flexfillheader");
        $("#menuNavBar2").addClass("flexfillheader-br");
    }
});

$('.arrow-down').on('click', function () {
    if ($(this).hasClass('active')) {
        $(this).toggleClass('active');
    } else {
        $('.arrow-down').removeClass('active');
        $(this).toggleClass('active');
    }
});

$('#nav-tab').on('click', function () {
    $('.arrow-right').removeClass('active');
    $('.arrow-down').removeClass('active');
    $('.collapse').collapse('hide');
});

$('.arrow-right').on('click', function () {
    if ($(this).hasClass('active')) {
        $(this).toggleClass('active');
    } else {
        $('.arrow-right').removeClass('active');
        $(this).toggleClass('active');
    }
});

$(".country-dropdown").click(function () {
    $(".country-options").toggleClass('d-none')
});
$('.dropdown-toggle').dropdown();

$('.toggle-mobile').click(function () {
    $(this).removeClass('active');
    $('.menu-mobile').removeClass('active');
    $('body').removeClass('disable-scroll');
});

$('.btn-close').click(function () {
    $(this).removeClass('active');
    $('.menu-mobile').removeClass('active');
    $('body').removeClass('disable-scroll');
});

$(".btnMenu").click(function () {
    $('.menu-mobile').addClass('active');
    $('body').addClass('disable-scroll');
    $('.toggle-mobile').addClass('active');
});

$(".navbar-nav li").mouseenter(function () {
    $(this).children('.nav-link').addClass('active')
    $(this).children('.dropdown-menu').addClass('show')
});
$(".navbar-nav li").mouseleave(function () {
    $(this).children('.nav-link').removeClass('active')
    $(this).children('.dropdown-menu').removeClass('show')
});
$('.box-descuento.d-none').remove();
$('.remove').remove();
var ripleyApp = document.getElementById('ripleyApp');
function boxRipleyApp() {
    if (/iPhone/i.test(navigator.userAgent)) {
        ripleyApp.style.display = 'flex';
    } else {
        ripleyApp.style.display = 'none';
    }
}

$(document).ready(function () {
    $('#menuNavBar .navbar-nav').find('li.active').removeClass('active');
    let pathname = window.location.pathname;
    if (pathname.includes("/index.html")) {
        // localStorage.setItem("opcMenuDesktop", "");
    } else {
        if (pathname.includes("/ripley-puntos.html")) {
            $("#rpg_select1").parent().addClass('active');
        }
        if (pathname.includes("/clientes-prime.html")) {
            $("#rpg_select2").parent().addClass('active');
            if (localStorage.getItem("flagBtnCanjesBanner") != null) {
                $("html,body").animate(
                    { scrollTop: $("#sectionBeneCategorias").offset().top - 50 },
                    0
                );
                localStorage.removeItem("flagBtnCanjesBanner");
            }
        }

        // if (pathname.includes("/canjes.html")) {
        //     if (!pathname.includes("/canjes.html?t=giftcardsSTR") && !pathname.includes("/canjes.html?t=experienciasSTR")) {
        //         $("#rpg_select3").parent().addClass('active');
        //     }  
        // }

        if (pathname.includes("/canjes.html")) {
            let parametrosUrl = window.location.search;
            let params = parametrosUrl.substring(1, parametrosUrl.length);
            let param1 = params.split("&")[0];
            if (param1.split("=")[1] == "giftcardsSTR" || param1.split("=")[1] == "experienciasSTR") {
                $("#rpg_select4").parent().addClass('active');
            } else {
                $("#rpg_select3").parent().addClass('active');
            }
        }

        if (pathname.includes("/afiliacion.html") || pathname.includes("/registro-omp.html")) {
            $("#rpg_select4").parent().addClass('active');
        }
    }

    var screensize = $(window).width();
    if (screensize < 1024) {
        $("#header").removeClass("ripley-header-flotante");
        $("#header").removeClass("pt-2");
        $("#header").addClass("ripley-header");
        $("#menu").removeClass("menu-br-flotante");
        $("#menu").addClass("menu-br");
        // $("#menuNavBar2").removeClass("flexfillheader-br");
        // $("#menuNavBar2").addClass("flexfillheader");
    }

    stopScrolling();

    var platform = "";
    if (/Android/i.test(navigator.userAgent)) {
        platform = "android";
    }
    else if (/iPhone/i.test(navigator.userAgent)) {
        platform = "iphone";
    }

    if (platform) {
        var clase = '.box-ripleyApp_link.' + platform;
        var links = $(clase);
        // $('.appLinkMobile.' + platform).removeClass("d-none");
        if (links) {
            links.removeClass("d-none");
        }
    }

    if (pathname.includes("/clientes-prime.html")) {
        var urlParams = window.location.search;
        if (urlParams.length > 0 && urlParams != "" && urlParams != undefined) {
            let params = urlParams.substring(1, urlParams.length);
            let param1 = params.split("=")[0];
            let param2 = params.split("=")[1];
            if (param1 == "sec" && param2 == "beneficios") {
                $("html,body").animate(
                    { scrollTop: $("#sectionBeneCategorias").offset().top - 40 },
                    0
                );
            }
        }
    }
    $('.btn_MP_ripley-puntos-go').removeClass('active');
    $('.dropdown-menu').removeClass('show');


});

$('#irComoHago').on('click', function () {

    if ($('#acor1-3').hasClass('show')) {
    }
    else {
        $('#nav-seguridad-tab').removeClass('show');
        $('#nav-seguridad-tab').removeClass('active');
        $('#nav-seguridad').removeClass('show');
        $('#nav-seguridad').removeClass('active');
        $('#nav-tutoriales-tab').addClass('active show');
        $('#nav-tutoriales').addClass('active show');

        $('.colorItemAcordion').attr('aria-expanded', 'false');
        $('.colorItemAcordion').removeClass('active');
        $('.colorItemAcordion').addClass('collapsed');

        $('.colorItemAcordion').last().removeClass('collapsed');
        $('.colorItemAcordion').last().addClass('active');
        $('.colorItemAcordion').last().attr('aria-expanded', 'true');

        $('#acor1-1').removeClass('show');
        $('#acor1-2').removeClass('show');
        // $('#acor1-3').hide();
        $('#acor1-3').toggleClass('active');
        $('#acor1-3').toggleClass('show');
    }

});

var check = 0; //cerrado
$('#nav-tab .nav-item').on('click', function () {
    var movil = $("#txt_movil");

    if (movil.css('display') == 'none') {
        if (check == 0) {
            $('#nav-tabContent').css("display", "none");
        }
        else {
            $('#nav-tabContent').css("display", "block");
        }
    }
    else {
        $('#nav-tabContent').css("display", "block");
    }

    if (check == 0) {
        check = 1;
    }
    else {
        check = 0;
    }
});

$(window).resize(function () {
    var movil = $("#txt_movil");

    if (movil.css('display') == 'none') {
        if (check == 1) {
            $('#nav-tabContent').css("display", "none");
        }
        else {
            $('#nav-tabContent').css("display", "block");
        }
    }
    else {
        $('#nav-tabContent').css("display", "block");
    }
});

$('.tabsToSelect .nav-item').on('click', function () {
    $('.tabsToSelect .nav-item').toggleClass('selectTabs');
});



