let isValidCelular=false;
$(document).ready(function() {
    validarNumericos();
    validarLetras();
    validarCelular();
    cargarRegistroOMP();

});

function cargarRegistroOMP(){

    let pathname = window.location.pathname;
    if (pathname.includes("registro-omp.html")){
        var objDataOMP=localStorage.getItem("objDataOMP");
        if (objDataOMP!="" && objDataOMP!=null) {
            let data = JSON.parse(objDataOMP);
            let nombreCompleto = localStorage.getItem("nombreRegistroOMP");
            if(data.status == 200){
                if (data.result=="Cliente ya Existe") {
                    $("#divRegistroOMP #tituloConfirmacionOMP").text("¡Hola,");
                    $("#divRegistroOMP .subtitleConfirmOMP").text("Ya te habías inscrito en el programa.");
                    // var texto = "Recuerda que ya puedes acumular RipleyPuntos Go. <br /> Solo tienes que presentar siempre tu DNI al momento de tu compra en Tiendas Ripley.";
                    // $("#divRegistroOMP .parrofoConfirmOMP").text(texto);
                    $("#parrafoNoExisteClientOMP").hide();
                    $("#parrafoExisteClientOMP").show();
                }
                $("#divRegistroOMP #nombreRegistroOMP").text(nombreCompleto);
                $("#divBannerCentroOMP").hide();
                $("#divBannerCentroOMPConfirm").show();
                localStorage.setItem("objDataOMP", "");
                localStorage.setItem("nombreRegistroOMP", "");
            } 
            else 
            {
                $("#divBannerCentroOMP").show();
                $("#divBannerCentroOMPConfirm").hide();
            }
        } else {
            $("#divBannerCentroOMP").show();
            $("#divBannerCentroOMPConfirm").hide();
        }
    }     
}
function RegisterClient_OMP(token){

        let nombrecompleto = $("#divRegistroOMP #txtnombrecompleto").val();
        let apellidos = $("#divRegistroOMP #txtapellidos").val();
        let documento='';
        let tipo_documento=''
        let txtInputDNI = $("#divRegistroOMP #dniPromocionesOMP");
        if(txtInputDNI[0].checked){
            documento = $("#divRegistroOMP #txtdocumentOMP").val();
            tipo_documento="DNI";
        } else {
            documento = $("#divRegistroOMP #txtdocument1OMP").val();
            tipo_documento="CE";
        }
        
        let celular = $("#divRegistroOMP #txtcelular").val();
        let email = $("#divRegistroOMP #txtemail").val();
        let checkTerminoYCondicionesOMP = $("#divRegistroOMP #datosPersonalesOMP");
        let TerminoYCondiciones="Yes";
        if (!(checkTerminoYCondicionesOMP && checkTerminoYCondicionesOMP[0] && checkTerminoYCondicionesOMP[0].checked)){
            TerminoYCondiciones="No";
        }
        let cuenta = $("#divRegistroOMP #fcuenta").val();
        let numero = $("#divRegistroOMP #fnumero").val();
        let campana = $("#divRegistroOMP #fcampana").val();
        

        let clientOMP = {
            email: email,
            emailType: "HTML",
            optedOutDate: "",
            optInDetails: "",
            leadSource: "",
            customFields: [
            {
                name: "DOCUMENTO",
                value: documento
            },
            {
                name: "TIPO_DOCUMENTO",
                value: tipo_documento
            },
            {
                name: "NOMBRES",
                value: nombrecompleto
            },
            {
                name: "APELLIDOS",
                value: apellidos
            },
            {
                name: "CELULAR",
                value: celular
            },
            {
                name: "TerminoYCondiciones",
                value: TerminoYCondiciones
            },
            {
                name: "campana",
                value: campana
            },
            {
                name: "cuenta",
                value: cuenta
            },
            {
                name: "numero",
                value: numero
            }
            ],
            captcha : token
        }
        localStorage.setItem("objDataOMP", "");
        localStorage.setItem("nombreRegistroOMP", "");
        pantallaDeCarga(true);
        postRegisterClientOMP(clientOMP)
        .done(function(data){
            if (data.status==200) {
                ripleyPuntosAnalytics.addEvent('Registro OMP', 'Registrar', 'Enviar', 0);
                localStorage.setItem("objDataOMP", JSON.stringify(data));
                localStorage.setItem("nombreRegistroOMP", nombrecompleto.trim()+"!");
            } 
            window.location.href = "./registro-omp.html";
            pantallaDeCarga(false);
        })
        .fail(function( jqXHR, textStatus, errorThrown ){
            
            pantallaDeCarga(false);
        })
    // }
};

$("#divRegistroOMP #btnEnviarOMP").on('click', function () { 
    let validaciones = false;
    let txtInputDNI = $("#divRegistroOMP #dniPromocionesOMP");
    if(txtInputDNI[0].checked){
        validaciones = (validaNombreCompleto() && validaApellidos() && validarDocumentoDNIOMP() && validaCelularOMP() && validaEmailOMP() && validaTerminoYCondicionesOMP_());
    }
    else {
        validaciones = (validaNombreCompleto() && validaApellidos() && validarDocumentoCEOMP() && validaCelularOMP() && validaEmailOMP() && validaTerminoYCondicionesOMP_());
    }
    
    if(!validaciones){
        //marcacionErrorNroDocumento();
    }
    if(validaciones){
        //var id = $('.g-recaptcha', form).attr('id');
        // grecaptcha.reset("recaptcha-omp");
        //grecaptcha.execute("recaptcha-omp");
        if(txtInputDNI[0].checked){
            generateDocumentosFromDNI();
        } else {
            generateDocumentosFromCE();
        }
        
        grecaptcha.execute();
    }
})

$("#divRegistroOMP #txtnombrecompleto").blur(function(){validaNombreCompleto();})
function validaNombreCompleto(){
    let isValid = true;
    let msg = "";
    let txtInput = $("#divRegistroOMP #txtnombrecompleto");
    let inputMessage = $("#divRegistroOMP #txtnombrecompleto-message");

    if (!(txtInput && txtInput.val() != "")){
        txtInput.addClass("is-invalid");
        msg = "Escribe tu nombre.";
        isValid = false;
    }
    else{
        if(txtInput.val().length <=0)
        {
            txtInput.addClass("is-invalid");
            msg = "Escribe tu nombre.";
            isValid = false;
        }
        else
        txtInput.removeClass("is-invalid");
    }

    if (msg){
        inputMessage.empty();
        inputMessage.html(msg);
    }

    return isValid;
}
$("#divRegistroOMP #txtapellidos").blur(function(){validaApellidos();})
function validaApellidos(){
    let isValid = true;
    let msg = "";
    let txtInput = $("#divRegistroOMP #txtapellidos");
    let inputMessage = $("#divRegistroOMP #txtapellidos-message");

    if (!(txtInput && txtInput.val() != "")){
        txtInput.addClass("is-invalid");
        msg = "Escribe tus apellidos.";
        isValid = false;
    }
    else{
        if(txtInput.val().length <=0)
        {
            txtInput.addClass("is-invalid");
            msg = "Escribe tus apellidos.";
            isValid = false;
        }
        else
        txtInput.removeClass("is-invalid");
    }

    if (msg){
        inputMessage.empty();
        inputMessage.html(msg);
    }

    return isValid;
}
$("#divRegistroOMP #txtdocumentOMP").blur(function(){validarDocumentoDNIOMP();generateDocumentosFromDNI();})
function validarDocumentoDNIOMP(){
    let isValid = true;
    let msg = "";
    let txtInput = $("#divRegistroOMP #txtdocumentOMP");
    let inputMessage = $("#divRegistroOMP #txtdocumentOMP-message");

    if (!(txtInput && txtInput.val() != "")){
        txtInput.addClass("is-invalid");
        msg = "Ingresa un DNI válido.";
        isValid = false;
    }
    else{
        if(txtInput.val().length !=8)
        {
            txtInput.addClass("is-invalid");
            msg = "Ingresa un DNI válido.";
            isValid = false;
        }
        else
        txtInput.removeClass("is-invalid");
    }

    if (msg){
        inputMessage.empty();
        inputMessage.html(msg);
    }

    return isValid;
}

$("#divRegistroOMP #txtdocument1OMP").blur(function(){validarDocumentoCEOMP();generateDocumentosFromCE();})
function validarDocumentoCEOMP(){
    let isValid = true;
    let msg = "";
    let txtInput = $("#divRegistroOMP #txtdocument1OMP");
    let inputMessage = $("#divRegistroOMP #txtdocument1OMP-message");

    if (!(txtInput && txtInput.val() != "")){
        txtInput.addClass("is-invalid");
        msg = "Ingresa un Carné de Extranjería válido.";
        isValid = false;
    }
    else{
        if(txtInput.val().length!=9)
        {
            txtInput.addClass("is-invalid");
            msg = "Ingresa un Carné de Extranjería válido.";
            isValid = false;
        }
        else
        txtInput.removeClass("is-invalid");
    }

    if (msg){
        inputMessage.empty();
        inputMessage.html(msg);
    }

    return isValid;
}

function generateDocumentosFromDNI(){
    let txtInput = $("#divRegistroOMP #txtdocumentOMP");
    if(txtInput.val().length==8) {
        txtInput = $("#divRegistroOMP #txtdocumentOMP");
        let x = Math.floor(Math.random() * (99 - 9)) + 9;
        $("#fcuenta").val("9900"+txtInput.val());
        $("#fnumero").val("990099"+txtInput.val()+x);
    } else {
        $("#fcuenta").val("");
        $("#fnumero").val("");
    }
}

function generateDocumentosFromCE(){
    let txtInput = $("#divRegistroOMP #txtdocument1OMP");
    if(txtInput.val().length==9) {
        txtInput = $("#divRegistroOMP #txtdocument1OMP");
        let x = Math.floor(Math.random() * (99 - 9)) + 9;
        $("#fcuenta").val("990"+txtInput.val());
        $("#fnumero").val("99099"+txtInput.val()+x);
    } else {
        $("#fcuenta").val("");
        $("#fnumero").val("");
    }
}


$("#divRegistroOMP #txtcelular").blur(function(){validaCelularOMP();})
function validaCelularOMP(){
    let isValid = true;
    let msg = "";
    let txtInput = $("#divRegistroOMP #txtcelular");
    let inputMessage = $("#divRegistroOMP #txtcelular-message");

    if (!(txtInput && txtInput.val() != "")){
        txtInput.addClass("is-invalid");
        msg = "Ingresa tu número de celular.";
        isValid = false;
    }
    else{
        if(txtInput.val().length !=9)
        {
            txtInput.addClass("is-invalid");
            msg = "Ingresa tu número de celular.";
            isValid = false;
        }
        else{
            if (!isValidCelular) {
                txtInput.addClass("is-invalid");
                msg = "Ingresa tu número de celular.";
                isValid = false;
            } 
            else
                txtInput.removeClass("is-invalid");
        }
    }
    if (msg){
        inputMessage.empty();
        inputMessage.html(msg);
    }

    return isValid;
}

$("#divRegistroOMP #txtemail").blur(function(){validaEmailOMP();})
function validaEmailOMP(){
    let isValid = true;
    let msg = "";
    let txtInput = $("#divRegistroOMP #txtemail");
    let inputMessage = $("#divRegistroOMP #txtemail-message");

    if (!(txtInput && txtInput.val() != "")){
        txtInput.addClass("is-invalid");
        msg = "Ingresa tu email.";
        isValid = false;
    }
    else{
        if(!validarCorreo(txtInput.val()))
        {
            txtInput.addClass("is-invalid");
            msg = "Ingresa un email válido.";
            isValid = false;
        }
        else
        txtInput.removeClass("is-invalid");
           
    }

    if (msg){
        inputMessage.empty();
        inputMessage.html(msg);
    }

    return isValid;
}
$("#divRegistroOMP #datosPersonalesOMP").blur(function(){validaTerminoYCondicionesOMP_();})
function validaTerminoYCondicionesOMP_(){
    let isValid = true;
//     let msg = "";
//     let txtInput = $("#divRegistroOMP #datosPersonalesOMP");
//     let inputMessage = $("#divRegistroOMP #datosPersonalesOMP-message");

//     if (!(txtInput && txtInput[0] && txtInput[0].checked)){
//         txtInput.addClass("is-invalid");
//         msg = "Por favor, autoriza el tratamiento de datos.";
//         isValid = false;
//     }
//     else{
//         txtInput.removeClass("is-invalid");
//     }
//    if (msg){
//         inputMessage.empty();
//         inputMessage.html(msg);
//     }
    return isValid;
}


var ultimoValorCelular = "";
function validarCelular(){
    var elementos = document.querySelectorAll('.cellphone');
    var regexNumeric = /[0-9]/;
     
    if(elementos && elementos.length > 0)
    {
        for(i=0;i<elementos.length;i++)
        {    
            $("#" + elementos[i].id).on('input', function(e){
                var length = $(this)[0].maxLength;
                if((regexNumeric.test(e.originalEvent.data)) || e.originalEvent.data == null){  
                    if(length > -1){
                        if(ultimoValorCelular.length < length){
                            ultimoValorCelular = $(this).val();
                            var startCell = ultimoValorCelular.substr(0,1);
                            var nroCelularMessage = $("#divRegistroOMP #txtcelular-message");
                            var nroCelular = $("#divRegistroOMP #txtcelular");
                            var msgNroCelular = "";
                            if(startCell != 9 && startCell.length > 0){
                                nroCelular.addClass("is-invalid");
                                msgNroCelular = "Ingresa tu número de celular.";
                                nroCelularMessage.html(msgNroCelular);
                                isValidCelular=false;
                                // nroCelularMessage.css("display","block");
                            }
                            else{
                                nroCelular.removeClass("is-invalid");
                                isValidCelular=true;
                                // nroCelularMessage.css("display","none");
                            }
                            // if(ultimoValorCelular.length == length && startCell == 9){
                            //     $("#divPaso4 .btn-check").show();
                            //     $(".btnEnviarCodigo").removeClass("btn-opacity");
                            //     $(".btnEnviarCodigo").prop("disabled",false);
                            // }
                        }
                        else{
                            if(e.originalEvent.data == null){
                                ultimoValorCelular = $(this).val();
                                // $("#divPaso4 .btn-check").hide();
                                // $(".btnEnviarCodigo").addClass("btn-opacity");
                                // $(".btnEnviarCodigo").prop("disabled",true);
                            }
                        }
                    }
                    else{
                        ultimoValorCelular = $(this).val();
                    }
                }   
                $(this).val(ultimoValorCelular);            
            });
            elementos[i].onpaste = function(e){
               e.preventDefault();
            }
        }
    }
}

var ultimoValorNumeros = "";
function validarNumericos(){
    var elementos = document.querySelectorAll('.onlyNumbers');
    var regexNumeric = /[0-9]/;
     
    if(elementos && elementos.length > 0)
    {
        for(i=0;i<elementos.length;i++)
        {    
            $("#" + elementos[i].id).on('input', function(e){
                var length = $(this)[0].maxLength;
                if((regexNumeric.test(e.originalEvent.data)) || e.originalEvent.data == null){  
                    if(length > -1){
                        if(ultimoValorNumeros.length < length){
                            ultimoValorNumeros = $(this).val();
                        }
                        else{
                            if(e.originalEvent.data == null){
                                ultimoValorNumeros = $(this).val();
                            }
                        }
                    }
                    else{
                        ultimoValorNumeros = $(this).val();  
                    }
                }
                $(this).val(ultimoValorNumeros);
            });
            elementos[i].onpaste = function(e){
               e.preventDefault();
            }
        }
    }
}
var ultimoValorLetras = "";
function validarLetras(){
    var elementos = document.querySelectorAll('.onlyLetters');
    var regexLetters = /^[áéíóúÁÉÍÓÚñÑa-zA-Z ]+$/;

    if(elementos && elementos.length > 0)
    {       
        for(i=0;i<elementos.length;i++)
        {     
            $("#" + elementos[i].id).on('input', function(e){
                var length = $(this)[0].maxLength;
                if((regexLetters.test(e.originalEvent.data)) || e.originalEvent.data == null){  
                    if(length > -1){
                        if(ultimoValorLetras.length < length){
                            ultimoValorLetras = $(this).val();
                        }
                        else{
                            if(e.originalEvent.data == null){
                                ultimoValorLetras = $(this).val();
                            }     
                        }
                    }
                    else{
                        ultimoValorLetras = $(this).val(); 
                    } 
                }
                $(this).val(ultimoValorLetras);
            });
            elementos[i].onpaste = function(e){
               e.preventDefault();
            }
        }
    }
}
function validarCorreo(correo){
    var mailformat = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return mailformat.test(correo);
}

$("#divRegistroOMP #dniPromocionesOMP").change(function(){
    let txtInput = $("#divRegistroOMP #txtdocumentOMP");
    $("#fcuenta").val("");
    $("#fnumero").val("");
    $("#divRegistroOMP #txtdocumentOMP").val("");
    $("#divRegistroOMP #txtdocumentOMP").removeClass("is-invalid")
    $("#divRegistroOMP #txtdocumentOMP-message").val("");

    $("#divRegistroOMP #txtdocument1OMP").val("");
    $("#divRegistroOMP #txtdocument1OMP").removeClass("is-invalid")
    $("#divRegistroOMP #txtdocument1OMP-message").val("");

    $('#divRegistroOMP #dniOMP').show();
    $('#divRegistroOMP #ceOMP').hide();

    validarCheckDniOMP();
    if ((txtInput && txtInput.val() != "")){
        validarDocumentoDNIOMP();
    }
})

function validarCheckDniOMP(){
    let txtInputCE = $("#divRegistroOMP #cePromocionesOMP");
    let txtInputDNI = $("#divRegistroOMP #dniPromocionesOMP");
    if(txtInputDNI[0].checked){
        txtInputCE[0].checked = false
    };
}

$("#divRegistroOMP #cePromocionesOMP").change(function(){
    let txtInput = $("#divRegistroOMP #txtdocumentOMP");
    $("#fcuenta").val("");
    $("#fnumero").val("");
    $("#divRegistroOMP #txtdocumentOMP").val("");
        $("#divRegistroOMP #txtdocumentOMP").removeClass("is-invalid")
        $("#divRegistroOMP #txtdocumentOMP-message").val("");
        
        $("#divRegistroOMP #txtdocument1OMP").val("");
        $("#divRegistroOMP #txtdocument1OMP").removeClass("is-invalid")
        $("#divRegistroOMP #txtdocument1OMP-message").val("");

        $('#divRegistroOMP #dniOMP').hide();
        $('#divRegistroOMP #ceOMP').show();
    validarCheckCEOMP();

    if ((txtInput && txtInput.val() != "")){
       
        

        validarDocumentoCEOMP();
    }
})
function validarCheckCEOMP(){
    let txtInputCE = $("#divRegistroOMP #cePromocionesOMP");
    let txtInputDNI = $("#divRegistroOMP #dniPromocionesOMP");
    if(txtInputCE[0].checked){
        txtInputDNI[0].checked = false
    }
}