$(document).ready(function () {
  
/*
  $(".cssmenu_ResDes").click(function (event) {
    $(".cssmenu_ResDes").removeClass("active");
    if (
      event.target.id == "menu_ResDes" ||
      event.target.id == "menu3ResponsiveFlecha"
    ) {
      $("#menu3ResponsiveFlecha").removeClass("active");
      if ($(this).hasClass("collapsed")) {
        $(this).addClass("active");
        $("#menu3ResponsiveFlecha").addClass("active");
      } else {
        $(this).removeClass("active");
      }
      $("#menu_ResDes2").addClass("collapsed");
      $("#acor1-menu-responsive2").removeClass("show");
    } else if (
      event.target.id == "menu_ResDes2" ||
      event.target.id == "menu4ResponsiveFlecha"
    ) {
      $("#menu4ResponsiveFlecha").removeClass("active");
      if ($(this).hasClass("collapsed")) {
        $(this).addClass("active");
        $("#menu4ResponsiveFlecha").addClass("active");
      } else {
        $(this).removeClass("active");
      }
      $("#menu_ResDes").addClass("collapsed");
      $("#acor1-menu-responsive").removeClass("show");
    }
  });
*/
  let pathname = window.location.pathname;
  if (pathname.includes("canjes.html")) {
    var urlParams = window.location.search;
    if (urlParams.length > 0 && urlParams != "" && urlParams != undefined) {
      selectOpcionTab(urlParams);
    }
  } else {
    localStorage.setItem("objDataCP", "");
    localStorage.setItem("flg_dniconsulted", "");
  }
 /* if (localStorage.getItem("flagBtnCanjesBanner") != null) {
    $("html,body").animate(
      { scrollTop: $("#" + localStorage.getItem("containerGoToCanjes")).offset().top - 50 },
      0
    );
    localStorage.removeItem("flagBtnCanjesBanner");
  }*/
  cargarSeccionSegmentacionCP();

  $("#slide-tabCanjePromo").carousel({
    interval: false
  });
});
$(".nav-linkMovil").click(function (event) {
  var links = document.getElementsByTagName("link");
  document.getElementById("pSuperCanjeOnly").style.display = "none";
  i = links.length;
  while (i--) {
    if (
      links[i].rel === "canonical" ||
      links[i].rel === "prev" ||
      links[i].rel === "next"
    ) {
      document.head.removeChild(links[i]);
    }
  }
  switch (event.target.id) {
    case "nav-giftcards-tab":
      if (!$(event.target).hasClass("active")) {
        $("#nav-multiplica-tab").removeClass("show");
        $("#nav-multiplica-tab").removeClass("active");
        $("#nav-multiplica").removeClass("show");
        $("#nav-multiplica").removeClass("active");
        $("#nav-multiplica-tab").attr("aria-selected", false);

        $("#nav-experiencias-tab").removeClass("show");
        $("#nav-experiencias-tab").removeClass("active");
        $("#nav-experiencias").removeClass("show");
        $("#nav-experiencias").removeClass("active");
        $("#nav-experiencias-tab").attr("aria-selected", false);

        $("#nav-supercanjes-tab").removeClass("show");
        $("#nav-supercanjes-tab").removeClass("active");
        $("#nav-supercanjes").removeClass("show");
        $("#nav-supercanjes-tab").removeClass("active");
        $("#nav-supercanjes-tab").attr("aria-selected", false);
      }
      break;
    case "nav-multiplica-tab":
      if (!$(event.target).hasClass("active")) {
        $("#nav-giftcards-tab").removeClass("show");
        $("#nav-giftcards-tab").removeClass("active");
        $("#nav-giftcards").removeClass("show");
        $("#nav-giftcards").removeClass("active");
        $("#nav-giftcards-tab").attr("aria-selected", false);

        $("#nav-experiencias-tab").removeClass("show");
        $("#nav-experiencias-tab").removeClass("active");
        $("#nav-experiencias").removeClass("show");
        $("#nav-experiencias").removeClass("active");
        $("#nav-experiencias-tab").attr("aria-selected", false);

        $("#nav-supercanjes-tab").removeClass("show");
        $("#nav-supercanjes-tab").removeClass("active");
        $("#nav-supercanjes").removeClass("show");
        $("#nav-supercanjes-tab").removeClass("active");
        $("#nav-supercanjes-tab").attr("aria-selected", false);
      }
      break;
    case "nav-experiencias-tab":
      if (!$(event.target).hasClass("active")) {
        $("#nav-giftcards-tab").removeClass("show");
        $("#nav-giftcards-tab").removeClass("active");
        $("#nav-giftcards").removeClass("show");
        $("#nav-giftcards").removeClass("active");
        $("#nav-giftcards-tab").attr("aria-selected", false);

        $("#nav-multiplica-tab").removeClass("show");
        $("#nav-multiplica-tab").removeClass("active");
        $("#nav-multiplica").removeClass("show");
        $("#nav-multiplica").removeClass("active");
        $("#nav-multiplica-tab").attr("aria-selected", false);

        $("#nav-supercanjes-tab").removeClass("show");
        $("#nav-supercanjes-tab").removeClass("active");
        $("#nav-supercanjes").removeClass("show");
        $("#nav-supercanjes-tab").removeClass("active");
        $("#nav-supercanjes-tab").attr("aria-selected", false);
        MostrarExperienciasPaginado(1);
      }

      break;
    case "nav-supercanjes-tab":
      if (!$(event.target).hasClass("active")) {
        $("#nav-giftcards-tab").removeClass("show");
        $("#nav-giftcards-tab").removeClass("active");
        $("#nav-giftcards").removeClass("show");
        $("#nav-giftcards").removeClass("active");
        $("#nav-giftcards-tab").attr("aria-selected", false);

        $("#nav-multiplica-tab").removeClass("show");
        $("#nav-multiplica-tab").removeClass("active");
        $("#nav-multiplica").removeClass("show");
        $("#nav-multiplica").removeClass("active");
        $("#nav-multiplica-tab").attr("aria-selected", false);

        $("#nav-experiencias-tab").removeClass("show");
        $("#nav-experiencias-tab").removeClass("active");
        $("#nav-experiencias").removeClass("show");
        $("#nav-experiencias").removeClass("active");
        $("#nav-experiencias-tab").attr("aria-selected", false);



        document.getElementById("pSuperCanjeOnly").style.display = "block";
        MostrarSuperCanjesPaginado(1);
      }

      break;
    default:
      break;
  }
});

$(".nav-linkDesktop").click(function (event) {
  var links = document.getElementsByTagName("link");
  document.getElementById("pSuperCanjeOnly").style.display = "none";
  i = links.length;
  while (i--) {
    if (
      links[i].rel === "canonical" ||
      links[i].rel === "prev" ||
      links[i].rel === "next"
    ) {
      document.head.removeChild(links[i]);
    }
  }

  switch (event.target.id) {
    case "nav-giftcards-tab":
      break;
    case "nav-multiplica-tab":
      break;
    case "nav-experiencias-tab":
      if (!$(event.target).hasClass("active")) {
        MostrarExperienciasPaginado(1);
      }
      break;
    case "nav-supercanjes-tab":
      document.getElementById("pSuperCanjeOnly").style.display = "block";
      if (!$(event.target).hasClass("active")) {
        MostrarSuperCanjesPaginado(1);
      }
      break;
    default:
      break;
  }
});
function cargarSeccionSegmentacionCP() {
  let flag = "0";
  let pathname = window.location.pathname;
  if (pathname.includes("canjes.html")) {
    var objData = localStorage.getItem("objDataCP");
    var dni_consulted = localStorage.getItem("flg_dniconsulted");
    if (
      dni_consulted != null &&
      dni_consulted != "" &&
      dni_consulted != undefined
    ) {
      flag = "1";
    }

    if (objData != null && objData != "") {
      let listData = JSON.parse(objData);
      if (listData.length > 0) {
        let clientePuntos = "";
        let nombres = "";
        let puntaje = "";
        let puntajeFaltante = "";
        let puntajePorcentaje = "";
        let totalRipleyPuntos = "";

        for (let y = 0; y < listData.length; y++) {
          if (listData[y].name == "CLASE_PUNTOS") {
            clasePuntos = listData[y].value;
          }
          if (listData[y].name == "CLASE_PUNTOS_CLIENTES") {
            clientePuntos = listData[y].value;
          }
          if (listData[y].name == "CLASE_PUNTOS_CLIENTES_OBJETIVO") {
            clientePuntosObjetivo = listData[y].value;
          }
          if (listData[y].name == "FECHA_RIPLEYPUNTOS") {
            fechaRipleyPuntos = listData[y].value;
          }
          if (listData[y].name == "NOMBRE") {
            nombres = listData[y].value;
          }
          if (listData[y].name == "PUNTAJE") {
            puntaje = listData[y].value;
          }
          if (listData[y].name == "PUNTAJE_FALTANTE") {
            puntajeFaltante = listData[y].value;
          }
          if (listData[y].name == "PUNTAJE_PORCENTAJE") {
            puntajePorcentaje = listData[y].value;
          }
          if (listData[y].name == "RIPLEYPUNTOS") {
            totalRipleyPuntos = listData[y].value;
          }
        }
        nombres = nombres + "!";

        if (clientePuntos.toUpperCase() == "SILVER PRIME") {

          $("#imgCategoriaSilver").attr("style", "display:block");
          $("#RestulConsultNothing").hide();
          $("#firstConsult").hide();
          $("#RestulConsult").show();
          $("#secondConsult").show();

          $("#secctionContenedorClientPrimeCP #nombreCP").text(nombres);
          $("#secctionContenedorClientPrimeCP #puntajeTotalCP").text(
            formatNumber.new(totalRipleyPuntos)
          );
          $("#secctionContenedorClientPrimeCP .catcliente").text(
            "SILVER"
          );
          $("#secctionContenedorClientPrimeCP #catclienteRIPLEY").hide();
          $("#secctionContenedorClientPrimeCP #catclienteSILVER").show();
          $("#secctionContenedorClientPrimeCP #catclienteGOLD").hide();
        } else if (clientePuntos.toUpperCase() == "GOLD PRIME") {
          $("#imgCategoriaGold").attr("style", "display:block");
          $("#RestulConsultNothing").hide();
          $("#firstConsult").hide();
          $("#RestulConsult").show();
          $("#secondConsult").show();

          $("#secctionContenedorClientPrimeCP #nombreCP").text(nombres);
          $("#secctionContenedorClientPrimeCP #puntajeTotalCP").text(
            formatNumber.new(totalRipleyPuntos)
          );
          $("#secctionContenedorClientPrimeCP .catcliente").text("GOLD");
          $("#secctionContenedorClientPrimeCP #catclienteRIPLEY").hide();
          $("#secctionContenedorClientPrimeCP #catclienteSILVER").hide();
          $("#secctionContenedorClientPrimeCP #catclienteGOLD").show();
        } else if (clientePuntos.toUpperCase() == "RIPLEY") {
          $("#imgCategoriaRipley").attr("style", "display:block");
          $("#RestulConsultNothing").hide();
          $("#firstConsult").hide();
          $("#RestulConsult").show();
          $("#secondConsult").show();

          $("#secctionContenedorClientPrimeCP #nombreCP").text(nombres);
          $("#secctionContenedorClientPrimeCP #puntajeTotalCP").text(
            formatNumber.new(totalRipleyPuntos)
          );
          $("#secctionContenedorClientPrimeCP .catcliente").text("RIPLEY");
          $("#secctionContenedorClientPrimeCP #catclienteRIPLEY").show();
          $("#secctionContenedorClientPrimeCP #catclienteSILVER").hide();
          $("#secctionContenedorClientPrimeCP #catclienteGOLD").hide();
        }
        localStorage.setItem("flg_dniconsulted", "");
      } else {
        "#RestulConsultNothing".show();
        $("#firstConsult").hide();
        $("#RestulConsult").hide();
        $("#secondConsult").show();
      }
    } else {
      if (
        dni_consulted != null &&
        dni_consulted != "" &&
        dni_consulted != undefined
      ) {
        $("#RestulConsultNothing").show();
        $("#firstConsult").hide();
        $("#RestulConsult").hide();
        $("#secondConsult").show();
        localStorage.setItem("flg_dniconsulted", "");
      } else {
        $("#RestulConsultNothing").hide();
        $("#firstConsult").show();
        $("#RestulConsult").hide();
        $("#secondConsult").hide();
      }
    }
    if (flag == "1") {
      $("html,body").animate(
        { scrollTop: $("#secctionContenedorClientPrimeCP").offset().top - 50 },
        0
      );
    }
  }
}
function selectOpcionTab(urlParams) {
  let params = urlParams.substring(1, urlParams.length);
  let param1 = params.split("&")[0];
  let param2 = params.split("&")[1];

  let valueparam1 = param1.split("=")[1];
  let valueparam2 = "";
  document.getElementById("pSuperCanjeOnly").style.display = "none";
  if (param2 != undefined) {
    valueparam2 = param2.split("=")[1];
  }
  switch (valueparam1) {
    case "giftcards":
      $("a.nav-linkDesktop1#nav-multiplica-tab").removeClass("show");
      $("a.nav-linkDesktop1#nav-multiplica-tab").removeClass("active");
      $("#nav-multiplica").removeClass("show");
      $("#nav-multiplica").removeClass("active");

      $("a.nav-linkDesktop1#nav-experiencias-tab").removeClass("show");
      $("a.nav-linkDesktop1#nav-experiencias-tab").removeClass("active");
      $("#nav-experiencias").removeClass("show");
      $("#nav-experiencias").removeClass("active");

      $("a.nav-linkDesktop1#nav-supercanjes-tab").removeClass("show");
      $("a.nav-linkDesktop1#nav-supercanjes-tab").removeClass("active");
      $("#nav-supercanjes").removeClass("show");
      $("#nav-supercanjes-tab").removeClass("active");

      $("a.nav-linkDesktop1#nav-giftcards-tab").addClass("active show");
      $("#nav-giftcards").addClass("active show");
      break;
    case "multiplica":
      $("a.nav-linkDesktop1#nav-giftcards-tab").removeClass("show");
      $("a.nav-linkDesktop1#nav-giftcards-tab").removeClass("active");
      $("#nav-giftcards").removeClass("show");
      $("#nav-giftcards").removeClass("active");

      $("a.nav-linkDesktop1#nav-experiencias-tab").removeClass("show");
      $("a.nav-linkDesktop1#nav-experiencias-tab").removeClass("active");
      $("#nav-experiencias").removeClass("show");
      $("#nav-experiencias").removeClass("active");

      $("a.nav-linkDesktop1#nav-supercanjes-tab").removeClass("show");
      $("a.nav-linkDesktop1#nav-supercanjes-tab").removeClass("active");
      $("#nav-supercanjes").removeClass("show");
      $("#nav-supercanjes-tab").removeClass("active");

      $("a.nav-linkDesktop1#nav-multiplica-tab").addClass("active show");
      $("#nav-multiplica").addClass("active show");
      break;
    case "experiencias":
    case "experienciasSTR":
      let _page = 1;
      if (valueparam2 != "") {
        _page = valueparam2;
      }
      MostrarExperienciasPaginado(_page);
      $("a.nav-linkDesktop1#nav-giftcards-tab").removeClass("show");
      $("a.nav-linkDesktop1#nav-giftcards-tab").removeClass("active");
      $("#nav-giftcards").removeClass("show");
      $("#nav-giftcards").removeClass("active");

      $("a.nav-linkDesktop1#nav-multiplica-tab").removeClass("show");
      $("a.nav-linkDesktop1#nav-multiplica-tab").removeClass("active");
      $("#nav-multiplica").removeClass("show");
      $("#nav-multiplica").removeClass("active");

      $("a.nav-linkDesktop1#nav-supercanjes-tab").removeClass("show");
      $("a.nav-linkDesktop1#nav-supercanjes-tab").removeClass("active");
      $("#nav-supercanjes").removeClass("show");
      $("#nav-supercanjes-tab").removeClass("active");
      if (valueparam1 == "experienciasSTR") {
        let d = document.getElementById("nav-top-carrousel");
        let d_nested = document.getElementById("nav-movil-supercanje");
        if (d != null && d_nested != null) {
          d.removeChild(d_nested);
        }
        $("a.nav-linkDesktop1#nav-supercanjes-tab").addClass("hide-tab");
        $("#nav-supercanjes-tab").addClass("hide-tab");
      }
      $("a.nav-linkDesktop1#nav-experiencias-tab").addClass("active show");
      $("#nav-experiencias").addClass("active show");
      //paginacion

      if (valueparam2 != "" && valueparam2.length < 1) {
        $("html,body").animate(
          { scrollTop: $("#divExperiencias").offset().top - 45 },
          0
        );
      }

      break;
    case "supercanjes":
      let _page2 = 1;
      if (valueparam2 != "") {
        _page2 = valueparam2;
      }
      $("#nav-top-carrousel").carousel('next');


      MostrarSuperCanjesPaginado(_page2);


      $("a.nav-linkDesktop1#nav-giftcards-tab").removeClass("show");
      $("a.nav-linkDesktop1#nav-giftcards-tab").removeClass("active");
      $("#nav-giftcards").removeClass("show");
      $("#nav-giftcards").removeClass("active");

      $("a.nav-linkDesktop1#nav-multiplica-tab").removeClass("show");
      $("a.nav-linkDesktop1#nav-multiplica-tab").removeClass("active");
      $("#nav-multiplica").removeClass("show");
      $("#nav-multiplica").removeClass("active");

      $("a.nav-linkDesktop1#nav-experiencias-tab").removeClass("show");
      $("a.nav-linkDesktop1#nav-experiencias-tab").removeClass("active");
      $("#nav-experiencias").removeClass("show");
      $("#nav-experiencias").removeClass("active");

      $("a.nav-linkDesktop1#nav-supercanjes-tab").addClass("active show");
      document.getElementById("pSuperCanjeOnly").style.display = "block";
      $("#nav-supercanjes").addClass("active show");
      if (valueparam2 != "") {
        $("html,body").animate(
          { scrollTop: $("#divSuperCanjes").offset().top - 100 },
          0
        );
      }

      break;
    case "giftcardsSTR":
      let d = document.getElementById("nav-top-carrousel");
      let d_nested = document.getElementById("nav-movil-supercanje");
      if (d != null && d_nested != null) {
        d.removeChild(d_nested);
      }
      $("a.nav-linkDesktop1#nav-supercanjes-tab").addClass("hide-tab");
      $("#nav-supercanjes-tab").addClass("hide-tab");
      $("a.nav-linkDesktop1#nav-giftcards-tab").addClass("active show");
      $("#nav-giftcards").addClass("active show");
      break;
    default:
      break;
  }
}
function MostrarExperienciasPaginado(page) {
  pantallaDeCarga(true);
  let _total_per_pages = 6;
  let _pages_total = 0;
  let result = Paginator(items_experiencias, page, _total_per_pages);
  _pages_total = result.total_pages;

  let divExperiencias = document.getElementById("divExperiencias");
  let htmlExperiencia = "";

  let parametrosUrl = window.location.search;
  let params = parametrosUrl.substring(1, parametrosUrl.length);
  let param1 = params.split("&")[0];


  let valueparam1 = "experiencias";
  if (param1.split("=")[1] == "giftcardsSTR" || param1.split("=")[1] == "experienciasSTR") {
    valueparam1 = "experienciasSTR";
  }

  result.data.forEach(function (element) {
    htmlExperiencia +=
      '<div class="col-12 col-lg-4 col-md-6 mt-4 mt-responsiveCP pb-3">';
    htmlExperiencia += '<div class="boxContainerExpeSuper mt-2">';
    htmlExperiencia +=
      '<div class="boxContainerExpeSuperExperiencia Col1_Expe">';
    htmlExperiencia +=
      '<div class="boxContainerExpeSuperTopExperiencia pl-3 d-none d-md-block" style="background: url(&quot;../' +
      rutaImgE +
      element.imgBack +
      '&quot;) center center no-repeat;background-size: cover;">';
    htmlExperiencia += '<div class="boxContainerExpeSuperTopChild">';
    htmlExperiencia +=
      '<p class="t2 text-center FontboxContainerExpeSuperTopChild">' +
      element.pricecurrent +
      "</p>";
    htmlExperiencia +=
      '<img style="margin-top: -5px;" src="' + rutaImgSVG + imgLogoWhite + '">';
    htmlExperiencia +=
      '<p class="t2 text-center FontboxContainerExpeSuperBottomChild">Antes: ';
    htmlExperiencia +=
      '<span class="FontboxContainerExpeSuperBottomChild" style="text-decoration:line-through;">' +
      element.priceante +
      "</span>";
    htmlExperiencia += "</p>";
    htmlExperiencia += "</div>";
    htmlExperiencia += "</div>";
    htmlExperiencia +=
      '<div class="boxContainerExpeSuperTopExperiencia pl-3 d-md-none" style="background: url(&quot;../' +
      rutaImgE +
      "xs-" +
      element.imgBack +
      '&quot;) center center no-repeat;background-size: cover;">';
    htmlExperiencia += '<div class="boxContainerExpeSuperTopChild">';
    htmlExperiencia +=
      '<p class="t2 text-center FontboxContainerExpeSuperTopChild">' +
      element.pricecurrent +
      "</p>";
    htmlExperiencia +=
      '<img style="margin-top: -5px;" src="' +
      rutaImgSVG +
      imgLogoWhite_min +
      '">';
    htmlExperiencia +=
      '<p class="t2 text-center FontboxContainerExpeSuperBottomChild">Antes: ';
    htmlExperiencia +=
      '<span class="FontboxContainerExpeSuperBottomChild" style="text-decoration:line-through;">' +
      element.priceante +
      "</span>";
    htmlExperiencia += "</p>";
    htmlExperiencia += "</div>";
    htmlExperiencia += "</div>";
    htmlExperiencia += '<div class="boxContainerExpeSuperBottomExperiencia pl-4 pt-2 pr-3" style="height:114px !important;">';
    // htmlExperiencia += '<div class="boxContainerExpeSuperBottomExperiencia pl-4 pt-2 pr-3">';
    htmlExperiencia +=
      '<p class="s9 semiBold purpleC2 titleboxExpeSuper py-1">' +
      element.titlebottom +
      "</p>";
    htmlExperiencia +=
      '<p class="s7 py-1 d-inline-block" style="color:#000000;height: 38px;line-height: 16px;">' +
      element.subtitlebottom +
      "</p>";
    if (element.titleLink != undefined && element.titleLink != null && element.titleLink != "") {
      htmlExperiencia += '<div class="text-right">';
      htmlExperiencia += '<a class ="s1 purple4 semiBold mt-1" href=' + element.hrefLink + ' target="_blank">' + element.titleLink + '<img class="ml-2" src="../img/svg/i-arrow-right-light.svg"></a/>';
      htmlExperiencia += '</div>';
    }
    htmlExperiencia += "</div>";
    htmlExperiencia += "</div>";
    htmlExperiencia += "</div>";
    htmlExperiencia += "</div>";
  });

  var headID = document.getElementsByTagName("head")[0];
  var linkCano = document.createElement("link");
  linkCano.rel = "canonical";

  var linkPrev = document.createElement("link");
  linkPrev.rel = "prev";
  var linkNext = document.createElement("link");
  linkNext.rel = "next";

  divExperiencias.innerHTML = htmlExperiencia;
  let paginador = document.getElementById("containerPaginationExperiencias");
  var htmlPaginador = "";
  if(_pages_total>1){
    htmlPaginador = "<nav>";
    htmlPaginador += '<ul class="pagination justify-content-center">';
    for (var i = 0; i < _pages_total; i++) {
      if (i == page - 1) {
        htmlPaginador += '<li class="page-item active">';
  
        linkCano.href = "canjes.html?t=" + valueparam1 + "&p=" + (i + 1);
        headID.appendChild(linkCano);
  
        if (0 == i) {
          linkNext.href = "canjes.html?t=" + valueparam1 + "&p=" + (i + 2);
          headID.appendChild(linkNext);
        }
        if (_pages_total - 1 == i) {
          linkPrev.href = "canjes.html?t=" + valueparam1 + "&p=" + i;
          headID.appendChild(linkPrev);
        }
  
        if (i > 0 && i < _pages_total - 1) {
          linkPrev.href = "canjes.html?t=" + valueparam1 + "&p=" + i;
          linkNext.href = "canjes.html?t=" + valueparam1 + "&p=" + (i + 2);
          headID.appendChild(linkPrev);
          headID.appendChild(linkNext);
        }
      } else {
        htmlPaginador += '<li class="page-item">';
      }
      htmlPaginador +=
        '<a class="page-link pagelinkItem" href="canjes.html?t=' + valueparam1 + '&amp;p=' +
        (i + 1) +
        '">' +
        (i + 1) +
        "</a>";
      htmlPaginador += "</li>";
    }
    htmlPaginador += "</ul>";
    htmlPaginador += "</nav>";
    paginador.innerHTML = htmlPaginador;
  }
  

  pantallaDeCarga(false);
}
function MostrarSuperCanjesPaginado(page) {
  pantallaDeCarga(true);
  //let _total_per_pages = 6; //antes
  let _total_per_pages = 9;
  let _pages_total = 0;
  let result = Paginator(items_supercanjes, page, _total_per_pages);
  _pages_total = result.total_pages;

  let divSuperCanjes = document.getElementById("divSuperCanjes");
  let htmlExperiencia = "";

  let parametrosUrl = window.location.search;
  let params = parametrosUrl.substring(1, parametrosUrl.length);
  let param1 = params.split("&")[0];


  let valueparam1 = "supercanjes";
  if (param1.split("=")[1] == "giftcardsSTR" || param1.split("=")[1] == "experienciasSTR") {
    valueparam1 = "supercanjesSTR";
  }

  result.data.forEach(function (element) {
    htmlExperiencia +=
      '<div class="col-12 col-lg-4 col-md-6 mt-4 mt-responsiveCP">';
    htmlExperiencia += '<div class="boxContainerExpeSuper mt-2">';
    htmlExperiencia +=
      '<div class="boxContainerExpeSuperExperiencia Col1_Expe">';
    htmlExperiencia +=
      '<div class="boxContainerExpeSuperTopExperiencia pl-3 d-none d-md-block" style="background: url(&quot;../' +
      rutaImgS +
      element.imgBack +
      '&quot;) center center no-repeat;background-size: cover;">';
    htmlExperiencia += '<div class="boxContainerExpeSuperTopChildSC">';
    htmlExperiencia +=
      '<p class="t2 text-center FontboxContainerExpeSuperTopChild">' +
      element.pricecurrent +
      "</p>";
    htmlExperiencia +=
      '<img style="margin-top: -5px;" src="' + rutaImgSVG + imgLogoWhite + '">';
    htmlExperiencia +=
      '<p class="t2 text-center FontboxContainerExpeSuperTopChildSC">' +
      element.priceother +
      "</p>";
    htmlExperiencia +=
      '<p class="t2 text-center FontboxContainerExpeSuperBottomChild">Antes: ';
    htmlExperiencia +=
      '<span class="FontboxContainerExpeSuperBottomChild" style="text-decoration:line-through;">' +
      element.priceante +
      "</span>";
    htmlExperiencia += "</p>";
    htmlExperiencia += "</div>";
    htmlExperiencia += "</div>";
    htmlExperiencia +=
      '<div class="boxContainerExpeSuperTopExperiencia pl-3 d-md-none" style="background: url(&quot;../' +
      rutaImgS +
      element.imgBack +
      '&quot;) center center no-repeat;background-size: cover;">';
    htmlExperiencia += '<div class="boxContainerExpeSuperTopChildSC">';
    htmlExperiencia +=
      '<p class="t2 text-center FontboxContainerExpeSuperTopChild">' +
      element.pricecurrent +
      "</p>";
    htmlExperiencia +=
      '<img style="margin-top: -5px;" src="' +
      rutaImgSVG +
      imgLogoWhite_min +
      '">';
    htmlExperiencia +=
      '<p class="t2 text-center FontboxContainerExpeSuperTopChildSC">' +
      element.priceother +
      "</p>";
    htmlExperiencia +=
      '<p class="t2 text-center FontboxContainerExpeSuperBottomChild">Antes: ';
    htmlExperiencia +=
      '<span class="FontboxContainerExpeSuperBottomChild" style="text-decoration:line-through;">' +
      element.priceante +
      "</span>";
    htmlExperiencia += "</p>";
    htmlExperiencia += "</div>";
    htmlExperiencia += "</div>";
    htmlExperiencia +=
      '<div class="boxContainerExpeSuperBottomExperiencia pl-4 pt-2 pr-3">';
    htmlExperiencia +=
      '<p class="s9 semiBold purpleC2 titleboxExpeSuper py-1">' +
      element.titlebottom +
      "</p>";
    htmlExperiencia +=
      '<p class="s7 py-1" style="color:#000000;height: 38px;line-height: 16px;">' +
      element.subtitlebottom +
      "</p>";
    htmlExperiencia += "</div>";
    htmlExperiencia += "</div>";
    htmlExperiencia += "</div>";
    htmlExperiencia += "</div>";
  });

  var headID = document.getElementsByTagName("head")[0];
  var linkCano = document.createElement("link");
  linkCano.rel = "canonical";

  var linkPrev = document.createElement("link");
  linkPrev.rel = "prev";
  var linkNext = document.createElement("link");
  linkNext.rel = "next";

  divSuperCanjes.innerHTML = htmlExperiencia;
  let paginador = document.getElementById("containerPaginationSuperCanjes");
  var htmlPaginador = "";
  htmlPaginador = "<nav >";
  if (_pages_total <= 5) {
    htmlPaginador += '<ul class="pagination justify-content-center">';
    htmlPaginador += generateliPagina(0, page, _pages_total, valueparam1);
    htmlPaginador += "</ul>";
  }
  else {
    htmlPaginador += '<ul class="pagination justify-content-center d-none d-sm-flex">';
    htmlPaginador += generateliPagina(0, page, _pages_total, valueparam1);
    htmlPaginador += "</ul>";
    htmlPaginador += '<ul class="pagination justify-content-center mt-2 d-sm-none">';
    htmlPaginador += slidePaginacion(page, _pages_total, valueparam1);
    htmlPaginador += "</ul>";
  }
  htmlPaginador += "</nav>";
  paginador.innerHTML = htmlPaginador;
  pantallaDeCarga(false);
}
function generateliPagina(initial, page, _pages_total, valueparam1) {
  var headID = document.getElementsByTagName("head")[0];
  var linkCano = document.createElement("link");
  linkCano.rel = "canonical";

  var linkPrev = document.createElement("link");
  linkPrev.rel = "prev";
  var linkNext = document.createElement("link");
  linkNext.rel = "next";

  let htmlPaginador = '';
  for (var i = initial; i < _pages_total; i++) {
    if (i == page - 1) {
      htmlPaginador += '<li class="page-item active">';

      linkCano.href = "canjes.html?t=" + valueparam1 + "&p=" + (i + 1);
      headID.appendChild(linkCano);

      if (0 == i) {
        linkNext.href = "canjes.html?t=" + valueparam1 + "&p=" + (i + 2);
        headID.appendChild(linkNext);
      }
      if (_pages_total - 1 == i) {
        linkPrev.href = "canjes.html?t=" + valueparam1 + "&p=" + i;
        headID.appendChild(linkPrev);
      }

      if (i > 0 && i < _pages_total - 1) {
        linkPrev.href = "canjes.html?t=" + valueparam1 + "&p=" + i;
        linkNext.href = "canjes.html?t=" + valueparam1 + "&p=" + (i + 2);
        headID.appendChild(linkPrev);
        headID.appendChild(linkNext);
      }
    } else {
      htmlPaginador += '<li class="page-item">';
    }
    htmlPaginador += '<a class="page-link pagelinkItem" href="canjes.html?t=' + valueparam1 + '&amp;p=' + (i + 1) + '">' + (i + 1) + "</a>";
    htmlPaginador += "</li>";
  }
  return htmlPaginador;
}
function slidePaginacion(page, _pages_total, valueparam1) {
  html = "";
  html += '<div class="nav nav-tabs d-sm-none" id="nav-tab" role="tablist">';
  html += '<div class="col-12 px-0 carousel slider slide" id="slide-tabDetallePaginacion" style="text-align: center;">';
  html += '<div class="carousel-inner" id="carrousel-movilSeguros">';
  html += (page>=1 && page<=5)? '<div class="carousel-item active">':'<div class="carousel-item">';
  html += '<ul class="pagination justify-content-center d-sm-none">';
  html += generateliPagina(0, page, 5, valueparam1);
  html += '</ul>';
  html += '</div>';
  html += (page>=6 && page<=10)? '<div class="carousel-item active">':'<div class="carousel-item">';
  html += '<ul class="pagination justify-content-center d-sm-none">';
  html += generateliPagina(5, page, 10, valueparam1);
  html += '</ul>';
  html += '</div>';
  html += (page>=11 && page<=15)? '<div class="carousel-item active">':'<div class="carousel-item">';
  html += '<ul class="pagination justify-content-center d-sm-none">';
  html += generateliPagina(10, page, _pages_total, valueparam1);
  html += '</ul>';
  html += '</div>';
  html += '</div>';
  html += '</div>';
  html += '</div>';
  html += '<a class="carousel-control-prev d-sm-none" style="height: 37px;margin-top: -10px;margin-left:-10px;" href="#slide-tabDetallePaginacion" role="button" data-slide="prev"><div class="divImgLinkControlCarousel"><img src="../img/svg/i-prev-flecha.svg" /></div></a>';
  html += '<a class="carousel-control-next d-sm-none" style="height: 37px;margin-top: -10px;margin-right:-1px" href="#slide-tabDetallePaginacion" role="button" data-slide="next"><div class="divImgLinkControlCarousel"><img src="../img/svg/i-next-flecha.svg" /></div></a>';
  return html;
}
function goToCanjes(params, container) {
  localStorage.setItem("flagBtnCanjesBanner", "true");
  localStorage.setItem("containerGoToCanjes", container);
  window.location.href = params;
}
