$(document).ready(function() {
    validarNumericos();
    validarLetras();
    validarCelular();
    cargarRegistroPromociones();
    obtenerValorParametro();
    // cargarRegistroCyber();
   // $('#acor1-cond_benef_promo').show();
});

function obtenerValorParametro() {
    var sPaginaURL = window.location.search.substring(1);
    var sURLVariables = sPaginaURL.split('=');
    $('#divRegistroPromociones #dniPRO').val(sURLVariables[1]);

    }

function cargarRegistroPromociones(){
    let pathname = window.location.pathname;
    if (pathname.includes("promociones.html")){
        var objDataOMP=localStorage.getItem("objDataPromotion");
        if (objDataOMP!="" && objDataOMP!=null) {
            let data = JSON.parse(objDataOMP);
            let nombreCompleto = localStorage.getItem("nombreRegistroPRO");
            if(data.status == 200){
                if (data.result=="Cliente ya registró una promoción") {
                    $("#divBannerCentroPROConfirm #tituloConfirmacionPRO").text("¡Hola!");
                    $("#divBannerCentroPROConfirm #subtitleConfirmPRO").text("Ya te habías inscrito en la campaña.");
                    var texto = "Recuerda que ya puedes multiplicar tus RipleyPuntos Go al comprar con tu Tarjeta Ripley los productos señalados.";
                    $("#divBannerCentroPROConfirm #parrafoNoExisteClientPRO").text(texto);
                }
                $("#divBannerCentroPROConfirm #nombreRegistroPRO").text(nombreCompleto);
                $("#divBannerCentroPRO").hide();
                $("#divBannerCentroPROConfirm").show();
                localStorage.setItem("objDataPromotion", "");
                localStorage.setItem("nombreRegistroPRO", "");
            } 
            else 
            {
                $("#divBannerCentroPRO").show();
                $("#divBannerCentroPROConfirm").hide();
            }
        } else {
            $("#divBannerCentroPRO").show();
            $("#divBannerCentroPROConfirm").hide();
        }
    }     
}

$("#divRegistroPromociones #dniPromociones").change(function(){
    console.log("dniPromociones");
    let txtInput = $("#divRegistroPromociones #txtdocument");

    $("#divRegistroPromociones #txtdocument").val("");
    $("#divRegistroPromociones #txtdocument").removeClass("is-invalid")
    $("#divRegistroPromociones #txtdocument-message").val("");

    $("#divRegistroPromociones #txtdocument1").val("");
    $("#divRegistroPromociones #txtdocument1").removeClass("is-invalid")
    $("#divRegistroPromociones #txtdocument1-message").val("");

    $('#divRegistroPromociones #dniPRO').show();
    $('#divRegistroPromociones #cePRO').hide();

    validarCheckDni();
    if ((txtInput && txtInput.val() != "")){
        validarDocumentoDNIPRO();
    }
})

function validarCheckDni(){
    let txtInputCE = $("#divRegistroPromociones #cePromociones");
    let txtInputDNI = $("#divRegistroPromociones #dniPromociones");
    if(txtInputDNI[0].checked){
        txtInputCE[0].checked = false
    };
}

$("#divRegistroPromociones #cePromociones").change(function(){
    console.log("cePromociones");
    let txtInput = $("#divRegistroPromociones #txtdocument1");
    $("#divRegistroPromociones #txtdocument").val("");
    $("#divRegistroPromociones #txtdocument").removeClass("is-invalid")
    $("#divRegistroPromociones #txtdocument-message").val("");

    $("#divRegistroPromociones #txtdocument1").val("");
    $("#divRegistroPromociones #txtdocument1").removeClass("is-invalid")
    $("#divRegistroPromociones #txtdocument1-message").val("");

    $('#divRegistroPromociones #dniPRO').hide();
    $('#divRegistroPromociones #cePRO').show();
    validarCheckCE();

    if ((txtInput && txtInput.val() != "")){
        validarDocumentoCEPRO();
    }
})
function validarCheckCE(){
    let txtInputCE = $("#divRegistroPromociones #cePromociones");
    let txtInputDNI = $("#divRegistroPromociones #dniPromociones");
    if(txtInputCE[0].checked){
        txtInputDNI[0].checked = false
    }
}
$("#divRegistroPromociones #txtdocument").blur(function(){validarDocumentoDNIPRO();})
function validarDocumentoDNIPRO(){
    let isValid = true;
    let msg = "";
    let txtInput = $("#divRegistroPromociones #txtdocument");
    let inputMessage = $("#divRegistroPromociones #txtdocument-message");

    if (!(txtInput && txtInput.val() != "")){
        txtInput.addClass("is-invalid");
        msg = "Ingresa un DNI válido.";
        isValid = false;
    }
    else{
        if(txtInput.val().length !=8)
        {
            txtInput.addClass("is-invalid");
            msg = "Ingresa un DNI válido.";
            isValid = false;
        }
        else
        txtInput.removeClass("is-invalid");
    }

    if (msg){
        inputMessage.empty();
        inputMessage.html(msg);
    }

    return isValid;
}

$("#divRegistroPromociones #txtdocument1").blur(function(){validarDocumentoCEPRO();})
function validarDocumentoCEPRO(){
    let isValid = true;
    let msg = "";
    let txtInput = $("#divRegistroPromociones #txtdocument1");
    let inputMessage = $("#divRegistroPromociones #txtdocument1-message");

    if (!(txtInput && txtInput.val() != "")){
        txtInput.addClass("is-invalid");
        msg = "Ingresa un Carné de Extranjería válido.";
        isValid = false;
    }
    else{
        if(txtInput.val().length != 9)
        {
            txtInput.addClass("is-invalid");
            msg = "Ingresa un Carné de Extranjería válido.";
            isValid = false;
        }
        else
        txtInput.removeClass("is-invalid");
    }

    if (msg){
        inputMessage.empty();
        inputMessage.html(msg);
    }

    return isValid;
}


// $("#divRegistroPromociones #txtdocument").blur(function(){validarDocumento();})
// function validarDocumento(){
//     let isValid = true;
//     let msg = "";
//     let txtInput = $("#divRegistroPromociones #txtdocument");
//     let txtInputCE = $("#divRegistroPromociones #cePromociones");
//     let txtInputDNI = $("#divRegistroPromociones #dniPromociones");
//     let inputMessage = $("#divRegistroPromociones #txtdocument-message");

//     if (!(txtInput && txtInput.val() != "")){
//         txtInput.addClass("is-invalid");
//         msg = "Ingresa un Número de Documento válido.";
//         isValid = false;
//     }
//     else{
//         if(txtInputDNI[0].checked && txtInput.val().length !=8)
//         {
//             txtInput.addClass("is-invalid");
//             msg = "Ingresa un DNI válido.";
//             isValid = false;
//         }
//         else if(txtInputCE[0].checked && txtInput.val().length !=11){
//             txtInput.addClass("is-invalid");
//             msg = "Ingresa un Carné de Extranjería válido.";
//             isValid = false;
//         }
//         else
//         txtInput.removeClass("is-invalid");
//     }

//     if (msg){
//         inputMessage.empty();
//         inputMessage.html(msg);
//     }

//     return isValid;
// }

$("#divRegistroPromociones #txtcelular").blur(function(){validaCelular();})
function validaCelular(){
    let isValid = true;
    let msg = "";
    let txtInput = $("#divRegistroPromociones #txtcelular");
    let inputMessage = $("#divRegistroPromociones #txtcelular-message");

    if (!(txtInput && txtInput.val() != "")){
        txtInput.addClass("is-invalid");
        msg = "Ingresa tu número de celular.";
        isValid = false;
    }
    else{
        if(txtInput.val().length !=9)
        {
            txtInput.addClass("is-invalid");
            msg = "Ingresa tu número de celular.";
            isValid = false;
        }
        else{
            if (!isValidCelular) {
                txtInput.addClass("is-invalid");
                msg = "Ingresa tu número de celular.";
                isValid = false;
            } 
            else
                txtInput.removeClass("is-invalid");
        }
            
    }

    if (msg){
        inputMessage.empty();
        inputMessage.html(msg);
    }

    return isValid;
}

$("#divRegistroPromociones #txtemail").blur(function(){validaEmail();})
function validaEmail(){
    let isValid = true;
    let msg = "";
    let txtInput = $("#divRegistroPromociones #txtemail");
    let inputMessage = $("#divRegistroPromociones #txtemail-message");

    if (!(txtInput && txtInput.val() != "")){
        txtInput.addClass("is-invalid");
        msg = "Ingresa tu email.";
        isValid = false;
    }
    else{
        if(!validarCorreo(txtInput.val()))
        {
            txtInput.addClass("is-invalid");
            msg = "Ingresa un email válido.";
            isValid = false;
        }
        else
        txtInput.removeClass("is-invalid");
           
    }

    if (msg){
        inputMessage.empty();
        inputMessage.html(msg);
    }

    return isValid;
}

$("#divRegistroPromociones #datosPersonalesPromociones").blur(function(){validaTerminoYCondicionesOMP();})
function validaTerminoYCondicionesOMP(){
    

    let isValid = true;
//     let msg = "";
//     let txtInput = $("#divRegistroPromociones #datosPersonalesPromociones");
//     let inputMessage = $("#divRegistroPromociones #datosPersonalesPromociones-message");

//     if (!(txtInput && txtInput[0] && txtInput[0].checked)){
//         txtInput.addClass("is-invalid");
//         msg = "Por favor, autoriza el tratamiento de datos.";
//         isValid = false;
//     }
//     else{
//         txtInput.removeClass("is-invalid");
//     }
//    if (msg){
//         inputMessage.empty();
//         inputMessage.html(msg);
//     }

    return isValid;
}

function RegisterPromotion(token){

    // let txtInputDNI = $("#divRegistroPromociones #dniPromociones");
    // let validaciones=false;
    // if(txtInputDNI[0].checked){
    //     validaciones =  (validarDocumentoDNIPRO() && validaCelular() && validaEmail() && validaTerminoYCondicionesOMP());
    // }
    // else {
    //     validaciones =  (validarDocumentoCEPRO() && validaCelular() && validaEmail() && validaTerminoYCondicionesOMP());
    // }


    // if (validaciones) {
        
        let ceCheked = $("#divRegistroPromociones #cePromociones");

        let documento='';
        let campana = $('#divRegistroPromociones #fcampana').val();
        //let document = $("#divRegistroPromociones #txtdocument").val();
        let celular = $("#divRegistroPromociones #txtcelular").val();
        let email = $("#divRegistroPromociones #txtemail").val();
        let checkTerminoYCondicionesPromociones = $("#divRegistroPromociones #datosPersonalesPromociones");
        let TerminoYCondiciones="OK";
        let tipoDocumento = "DNI"
        if (!(checkTerminoYCondicionesPromociones && checkTerminoYCondicionesPromociones[0] && checkTerminoYCondicionesPromociones[0].checked)){
            TerminoYCondiciones="";
        }

        if(ceCheked[0].checked){
            tipoDocumento= "CE";
            documento = $("#divRegistroPromociones #txtdocument1").val();
        } else {
            tipoDocumento= "DNI";
            documento = $("#divRegistroPromociones #txtdocument").val();
        }

        let dataPromotion = {
            email: email,
            emailType: "HTML",
            optedOutDate: "",
            optInDetails: "",
            leadSource: "",
            captcha : token,
            customFields: [
            {
                name: "DOCUMENTO",
                value: documento
            },
            {
                name: "TIPO_DOCUMENTO",
                value: tipoDocumento
            },
            {
                name: "CELULAR",
                value: celular
            },
            {
                name: "MARCA_PD",
                value: TerminoYCondiciones
            },
            {
                name: "CAMPANA",
                value: campana
            }
            ]
        }
        localStorage.setItem("objDataPromotion", "");
        // localStorage.setItem("nombreRegistroPRO", "");
        pantallaDeCarga(true);
        postRegisterPromociones(dataPromotion)
        .done(function(data){
            if (data.status==200) {
                ripleyPuntosAnalytics.addEvent('Registro Promocion', 'Registrar', 'Enviar', 0);
                localStorage.setItem("objDataPromotion", JSON.stringify(data));
             } 
            window.location.href = "./promociones.html";
            pantallaDeCarga(false);
        })
        .fail(function( jqXHR, textStatus, errorThrown ){
            pantallaDeCarga(false);
        })
};

$("#divRegistroPromociones #btnEnviarPRO").on('click', function () { 
    let validaciones = false;
    
    let txtInputDNI = $("#divRegistroPromociones #dniPromociones");
    if(txtInputDNI[0].checked){
        validaciones =  (validarDocumentoDNIPRO() && validaCelular() && validaEmail() && validaTerminoYCondicionesOMP());
    }
    else {
        validaciones =  (validarDocumentoCEPRO() && validaCelular() && validaEmail() && validaTerminoYCondicionesOMP());
    }
    
    
    if(!validaciones){
        //marcacionErrorNroDocumento();
    }
    if(validaciones){
        grecaptcha.execute();
        // RegisterPromotion("");
    }
})
// //#region RegistroCyber
// function RegisterPromotionCyber(token){
//         let documento='';
//         let tipoDocumento = "DNI"
//         let form = $('#divRegistroPromociones #fcampanablackweek').val();
//         let celular = $("#divRegistroPromociones #txtcelular").val();
//         let email = $("#divRegistroPromociones #txtemail").val();

//         let ceCheked = $("#divRegistroPromociones #cePromociones");
//         if(ceCheked[0].checked){
//             tipoDocumento= "CE";
//             documento = $("#divRegistroPromociones #txtdocument1").val();
//         } else {
//             tipoDocumento= "DNI";
//             documento = $("#divRegistroPromociones #txtdocument").val();
//         }

//         let privacyPolicy="No";
//         let checkPoliticaPrivacidad = $("#divRegistroPromociones #datosPersonalesPromociones");
//         if ((checkPoliticaPrivacidad && checkPoliticaPrivacidad[0] && checkPoliticaPrivacidad[0].checked)){
//             privacyPolicy="Yes";
//         }
    
//         let trama='';
//         trama += 'formId►'+ form +'▲';
//         trama += 'names►▲';
//         trama += 'firstName►▲';
//         trama += 'lastName►▲';
//         trama += 'documentType►'+ tipoDocumento +'▲';
//         trama += 'documentNumber►'+ documento +'▲';
//         trama += 'email►'+ email +'▲';
//         trama += 'mobilePhone►'+ celular +'▲';
//         trama += 'promotionCode►▲';
//         trama += 'purchaseNumber►▲';
//         trama += 'turnBuy►▲';
//         trama += 'businessName►▲';
//         trama += 'privacyPolicy►'+ privacyPolicy +'▲';
//         trama += 'CampaignName►Campaña black week';
        
//         let InscriptionFormCms = {
//             trama: trama,
//             captcha : token,
//             sourcecaptcha: "RP"
//         }

//         localStorage.setItem("objDataPromotionCyber", "");
//         pantallaDeCarga(true);
//         postRegisterPromocionesCyber(InscriptionFormCms)
//         .done(function(data){
//             if (data.status==200) {
//                 ripleyPuntosAnalytics.addEvent('Registro Promocion Black Week', 'Registrar', 'Enviar', 0);
//                 localStorage.setItem("objDataPromotionCyber", JSON.stringify(data));
//              } 
//             window.location.href = "./black-week.html";
//             pantallaDeCarga(false);
//         })
//         .fail(function( jqXHR, textStatus, errorThrown ){
//             pantallaDeCarga(false);
//         })


// };
// function cargarRegistroCyber(){
//     let pathname = window.location.pathname;
//     if (pathname.includes("black-week.html")){
//         var objDataOMP=localStorage.getItem("objDataPromotionCyber");
//         if (objDataOMP!="" && objDataOMP!=null) {
//                 $("#divBannerCentroPRO").hide();
//                 $("#divBannerCentroPROConfirm").show();
//                 localStorage.setItem("objDataPromotionCyber", "");
//         } else {
//             $("#divBannerCentroPRO").show();
//             $("#divBannerCentroPROConfirm").hide();
//         }
//     }     
// }
// //#endregion