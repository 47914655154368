$(document).ready(function() {
  $("#seccionGoldBeneficio").css("margin-left", "100%");
  $("#seccionSilverBeneficio").css("margin-left", "100%");
  $("#markerGoldBeneficio").hide();
});

function complete() {}
function mostrarPlusBeneficio(event){
  document.getElementById("hrefmostrarPlusBeneficio").classList.remove("d-head-bg");
  document.getElementById("hrefmostrarSilverBeneficio").classList.add("d-head-bg");
  document.getElementById("hrefmostrarGoldBeneficio").classList.add("d-head-bg");
  $("#markerGoldBeneficio").hide();
  $("#markerGreyBeneficio").show();
  document.getElementById("seccionPlusBeneficio").style.display="block";
  $("#seccionGoldBeneficio").animate(
    {
      "margin-left": "100%"
    },
    500
  );
  $("#seccionSilverBeneficio").animate(
    {
      "margin-left": "-100%"
    },
    500
  );


  $("#seccionPlusBeneficio").animate(
    {
      "margin-left": "0%"
    },
    500
  );
  setTimeout(function(){ document.getElementById("seccionGoldBeneficio").style.display="none"; }, 400);
  setTimeout(function(){ document.getElementById("seccionSilverBeneficio").style.display="none"; }, 400);
  
}
function mostrarSilverBeneficio(event) {
  document.getElementById("hrefmostrarPlusBeneficio").classList.add("d-head-bg");
  document.getElementById("hrefmostrarSilverBeneficio").classList.remove("d-head-bg");
  document.getElementById("hrefmostrarGoldBeneficio").classList.add("d-head-bg");

  $("#markerGoldBeneficio").hide();
  $("#markerGreyBeneficio").show();
  document.getElementById("seccionSilverBeneficio").style.display="block";
  $("#seccionGoldBeneficio").animate(
    {
      "margin-left": "100%"
    },
    500
  );
  $("#seccionPlusBeneficio").animate(
    {
      "margin-left": "100%"
    },
    500
  );

  $("#seccionSilverBeneficio").animate(
    {
      "margin-left": "0%"
    },
    500
  );
  setTimeout(function(){ document.getElementById("seccionGoldBeneficio").style.display="none"; }, 400);
  setTimeout(function(){ document.getElementById("seccionPlusBeneficio").style.display="none"; }, 400);
}
function mostrarGoldBeneficio(event) {
  $("#markerGreyBeneficio").hide();
  $("#markerGoldBeneficio").show();
  document.getElementById("hrefmostrarPlusBeneficio").classList.add("d-head-bg");
  document.getElementById("hrefmostrarSilverBeneficio").classList.add("d-head-bg");
  document.getElementById("hrefmostrarGoldBeneficio").classList.remove("d-head-bg");
  document.getElementById("seccionGoldBeneficio").style.display="block";
  $("#seccionPlusBeneficio").animate(
    {
      "margin-left": "-100%"
    },
    500
  );
  $("#seccionSilverBeneficio").animate(
    {
      "margin-left": "-100%"
    },
    500
  );
  $("#seccionGoldBeneficio").animate(
    {
      "margin-left": "0%"
    },
    500
  );
  setTimeout(function(){ document.getElementById("seccionSilverBeneficio").style.display="none"; }, 400);
  setTimeout(function(){ document.getElementById("seccionPlusBeneficio").style.display="none"; }, 400);
}

function OpenPreguntasFrec(params) {
  let param1=params.split("-")[0];
  let param2=params.split("-")[1];
  for (let index = 1; index < 10; index++) {
    if(document.getElementById(param1+"-"+index) != null){
      if(param2==index){
        if(document.getElementById(params).style.display == "none"){
          document.getElementById(params).style.display="block";
        }
        else{
          document.getElementById(params).style.display="none";
        }    
      }
      else{
        document.getElementById(param1+"-"+index).style.display="none";
      }
    }
  }
  
  $('html, body').animate({
    scrollTop: $("#btn-"+params).offset().top - 75
    }, 900);
  // alert("Please");
  // $('html, body').animate({ scrollTop: $("#btn-"+params).offset().top - 75 }, 900);
  // $('html, body').animate({ scrollTop: $('#btn-'+params).offset().top - 75 }, 'slow');

}

$('#supercanjes .pagination .page-item').on('click',function(e){
  console.log("entro");
})